import styled from "styled-components";
import Div from "./Div";
import { cssBreakpoints } from "../global/theme";
import { ReactNode } from "react";

interface Props {
  title?: string;
  children?: ReactNode;
}

const StyledCard = styled(Div)`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey20};
  box-shadow: 0 10px 20px 0rgba (0, 0, 0, 0.05);
`;

const StyledTitle = styled(Div)`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledCardBody = styled(Div)`
  ${cssBreakpoints("padding", [{ sm: "1rem .5rem" }, { md: "1.25rem" }])}
`;

const RentableCard = ({ title, children }: Props) => {
  return (
    <StyledCard mb={{ default: 3 }} width={{ default: 1 }}>
      {title && (
        <StyledTitle alignItems="center" p={{ default: 4 }}>
          {title}
        </StyledTitle>
      )}
      <StyledCardBody
        justifyContent="center"
        alignItems="center"
        width={{ default: 1 }}
      >
        {children}
      </StyledCardBody>
    </StyledCard>
  );
};

export default RentableCard;
