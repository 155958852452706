import { useHistory } from "react-router-dom";
import Button from "../../../../components/baseComponents/Button";
import Div from "../../../../components/baseComponents/Div";
import Row from "../../../../components/baseComponents/Row";

const RentManagerNucleusDashboard = () => {
  const history = useHistory();
  return (
    <Div width={{ default: 1 }} alignItems="center">
      <Div width={{ default: 4 / 12 }} mt={{ default: 5 }}>
        <img
          src="https://rentable-public-assets.s3.us-east-2.amazonaws.com/rent-manager.png"
          alt="Rent Manager"
        />
      </Div>
      <Div width={{ default: 9 / 12 }} justifyContent="center">
        <Row justifyContent="center" mt={{ default: 3 }}>
          <Div width={{ default: 6 / 12 }}>
            <Button
              text="Failed Invites Dashboard"
              onClick={() =>
                history.push(
                  "/nucleus/integrations/rent-manager/failed-invites"
                )
              }
            />
          </Div>
        </Row>
        <Row justifyContent="center" mt={{ default: 3 }}>
          <Div width={{ default: 6 / 12 }}>
            <Button
              text="Failed Dibursements Dashboard"
              onClick={() =>
                history.push(
                  "/nucleus/integrations/rent-manager/failed-disbursements"
                )
              }
            />
          </Div>
        </Row>
      </Div>
    </Div>
  );
};

export default RentManagerNucleusDashboard;
