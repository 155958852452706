import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import EllipsisIcon from "../../images/EllipsisIcon";
import MenuIcon from "../../images/MenuIcon";
import UserInitialsAvatar from "./UserInitialsAvatar";
import { MenuItem } from "../global/ModelInterfaces";
import { useAppSelector } from "../../store/hooks";
import Div from "./Div";
interface Props {
  menuItems: MenuItem[];
  style?: string;
}

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

interface IconProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onBlur?: React.MouseEventHandler<HTMLDivElement>;
  ref?: React.ClassAttributes<HTMLDivElement>;
  isMenuOpen?: boolean;
}

const StyledIcon = styled(Div)<IconProps>`
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundPrimary};
    border-radius: ${(props) => props.theme.border_radius.SM};
  }

  ${(props) =>
    variant({
      prop: "isMenuOpen",
      variants: {
        true: {
          backgroundColor: props.theme.colors.backgroundPrimary,
          borderRadius: props.theme.border_radius.SM,
        },
      },
    })}
`;

const StyledMenuList = styled(Div)`
  align-items: flex-start;
  position: absolute;
  box-shadow: ${(props) => props.theme.box_shadow.light};
  border-radius: ${(props) => props.theme.border_radius.SM};
  background-color: ${(props) => props.theme.colors.white};
  z-index: 10;
`;

const StyledMenuItem = styled(Div)`
  z-index: 99;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Menu = ({ menuItems, style = "initials" }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const currentUser = useAppSelector((state) => state.auth.user);

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutsideMenu = (event: any) => {
      if (ref.current) {
        setIsMenuOpen(ref.current.contains(event.target));
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [ref]);

  return (
    <>
      {menuItems.length > 0 && (
        <StyledMenu ref={ref}>
          <StyledIcon
            onClick={openMenu}
            onBlur={() => setIsMenuOpen(false)}
            isMenuOpen={isMenuOpen}
            p={{ default: 3 }}
          >
            {style == "ellipsis" && <EllipsisIcon />}
            {style == "hamburger" && <MenuIcon />}
            {style == "initials" && (
              <UserInitialsAvatar
                name={currentUser.first_name + " " + currentUser.last_name}
              />
            )}
          </StyledIcon>

          {isMenuOpen && (
            <StyledMenuList
              pl={{ default: 1 }}
              pr={{ default: 1 }}
              pb={{ default: 4 }}
              mt={{ default: 6 }}
            >
              {menuItems?.map((item) => (
                <StyledMenuItem
                  onClick={() => {
                    setIsMenuOpen(false);
                    item.function();
                  }}
                  pt={{ default: 4 }}
                  pl={{ default: 4 }}
                  pr={{ default: 4 }}
                  ml={{ default: 3 }}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </StyledMenuList>
          )}
        </StyledMenu>
      )}
    </>
  );
};

export default Menu;
