import styled from "styled-components";
import Div from "./Div";
import { cssBreakpoints } from "../global/theme";
import { ReactNode } from "react";

interface Props {
  title?: string;
  children?: ReactNode;
}

const BannerWrapper = styled(Div)`
  background-color: ${(props) => props.theme.colors.primary_transparent};
  color: ${(props) => props.theme.colors.black};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-radius: ${(props) => props.theme.border_radius.MD};
`;

const BannerTitle = styled(Div)`
  background-color: ${(props) => props.theme.colors.primary_transparent};
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  border-radius: ${(props) => props.theme.border_radius.MD_TOP};
`;

const BannerBody = styled(Div)`
  ${cssBreakpoints("padding", [{ sm: "1rem" }, { md: "1.5rem" }])};
`;

const RentableBanner = ({ title, children }: Props) => {
  return (
    <BannerWrapper mb={{ default: 3 }} width={{ default: 1 }}>
      {title && (
        <BannerTitle alignItems="center" p={{ default: 2 }}>
          {title}
        </BannerTitle>
      )}
      <BannerBody
        justifyContent="center"
        alignItems="center"
        width={{ default: 1 }}
      >
        {children}
      </BannerBody>
    </BannerWrapper>
  );
};

export default RentableBanner;
