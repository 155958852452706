import { useState } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import { updateCompanySection } from "../../../../../store/nucleusCompanies";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";
import {
  retrieveYardiDbAccounts,
  createYardiCompany,
} from "../../../../utils/yardiUtils";

import { Option } from "../../../../../components/global/ModelInterfaces";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Container from "../../../../../components/baseComponents/Container";
import NucleusLoader from "../../../../components/NucleusLoader";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import SuccessMessage from "../../../../../components/baseComponents/SuccessMessage";

import YardiLogo from "./YardiLogo";
import SetYardiConnectionForm from "./SetYardiConnectionForm";
import SetYardiAccountingForm from "./SetYardiAccountingForm";
import integrationType from "../../../../../enums/integrationType";
import Button from "../../../../../components/baseComponents/Button";
import { YardiCompanyData } from "../interfaces/YardiCompanyInterface";
import { IntegrationDetailsInterface } from "../interfaces/YardiIntegrationDetailsInterface";
import CollapsibleJson from "../../../../../components/baseComponents/CollapsibleJson";

const CreateYardiCompanyForm = () => {
  const { company } = useCompanyFromParams();
  const dispatch = useAppDispatch();
  const [collectAccountingInfo, setCollectAccountingInfo] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [invite, setInvite] = useState("");
  const [foundBuildings, setFoundBuildings] = useState(0);
  const [glAccountsOptions, setGlAccountsOptions] = useState<Option[]>([]);
  const [yardiCompanyUuid, setYardiCompanyUuid] = useState("");
  const [rawResponse, setRawResponse] = useState(null);

  const handleConnectYardiCompany = (
    yardiCompanySettings: YardiCompanyData
  ) => {
    // I. Check if connection settings are correctly provided
    const successCallback = (
      integrationDetails: IntegrationDetailsInterface
    ) => {
      setFoundBuildings(integrationDetails.properties_qty);
      setGlAccountsOptions(integrationDetails.gl_accounts);
      // Update the company section with validated Yardi company info
      dispatch(
        updateCompanySection(
          company.uuid,
          "yardi_company_info",
          integrationDetails.yardi_company_info
        )
      );

      setError("");
      setLoading(false);
      setCollectAccountingInfo(true);
    };
    const failureCallback = (error: any) => {
      setFoundBuildings(0);
      setGlAccountsOptions([]);
      setLoading(false);
      setError(error.data?.message);
      setRawResponse(error.data?.raw_response);
    };

    setLoading(true);
    setRawResponse(null);
    retrieveYardiDbAccounts(
      yardiCompanySettings,
      successCallback,
      failureCallback
    );
  };

  const handleCreateYardiCompany = (
    yardiConnectionSettings: IntegrationDetailsInterface
  ) => {
    // II. Create Yardi Integration for the current company

    const successCallback = (
      integrationDetails: IntegrationDetailsInterface
    ) => {
      setLoading(false);
      setCollectAccountingInfo(false);
      setInvite(integrationDetails.invite_link);
      setYardiCompanyUuid(integrationDetails.yardi_company_uuid);
      // Turn ON Yardi integration type for this company
      dispatch(updateCompanySection(company.uuid, "integration_name", "Yardi"));
      dispatch(
        updateCompanySection(
          company.uuid,
          "integration_details",
          integrationDetails.integration_details
        )
      );
    };

    const failureCallback = (error: any) => {
      setError(error.data?.message || error.statusText);
      setLoading(false);
    };

    setLoading(true);
    setError("");
    createYardiCompany(
      { ...company, ...yardiConnectionSettings },
      successCallback,
      failureCallback
    );
  };

  const clickConfirmHandler = () => {
    // Will immediately switch to the Yardi general integration tab view
    dispatch(
      updateCompanySection(
        company.uuid,
        "integration_type",
        integrationType.YARDI
      )
    );
  };

  if (loading) {
    return (
      <Row justifyContent="center">
        <NucleusLoader loadingText="Creating Yardi Company..." />
      </Row>
    );
  }

  return (
    <Div width={{ default: 1 }}>
      <Row alignItems="center" justifyContent="center" mb={{ default: 2 }}>
        <h2>
          <YardiLogo />
        </h2>
      </Row>
      {error && (
        <Row mb={{ default: 3 }} justifyContent="center">
          <ErrorMessage>
            <h4>{error}</h4>
          </ErrorMessage>
          {error && (
            <CollapsibleJson
              json={rawResponse || {}}
              title="Raw Response"
              collapsible={true}
            />
          )}
        </Row>
      )}

      {/* Step I. Connection settings */}
      {!collectAccountingInfo && !invite && (
        <SetYardiConnectionForm
          onCompletedCallback={handleConnectYardiCompany}
        />
      )}

      {/* Step II. Accounting settings */}
      {collectAccountingInfo && !error && (
        <Row mb={{ default: 3 }} justifyContent="center">
          <SuccessMessage>
            <h4>Successfully connected to Yardi DB!</h4>
          </SuccessMessage>
        </Row>
      )}
      {collectAccountingInfo && (
        <SetYardiAccountingForm
          yardiCompanySettings={company}
          foundBuildings={foundBuildings}
          glAccountsOptions={glAccountsOptions}
          onCompletedCallback={handleCreateYardiCompany}
        />
      )}

      {/* Step III. Display the invite link */}
      {!!invite && (
        <Container>
          <Row>
            <SuccessMessage>
              <h2>Success!</h2>
            </SuccessMessage>
          </Row>
          <Row>
            <Div width={{ default: 1 }} mb={{ default: 3 }}>
              <Row>Rentable company UUID: {company.uuid}</Row>
              <Row>Yardi company UUID: {yardiCompanyUuid}</Row>
              <Row>
                Representative: {company.yardi_company_info?.firstName}{" "}
                {company.yardi_company_info?.lastName}
              </Row>
              <Row>Email: {company.yardi_company_info?.email}</Row>
              <Row>
                Cash Account: <b>{company.integration_details?.yardiBankId}</b>
              </Row>
              <Row>
                Deposit Account:{" "}
                <b>{company.integration_details?.depositAccount}</b>
              </Row>
              <Row>
                Payable Account:{" "}
                <b>{company.integration_details?.disbursementAccount}</b>
              </Row>
              <Row>
                Revenue Account:{" "}
                <b>{company.integration_details?.revenueAccount}</b>
              </Row>
            </Div>
          </Row>
          <Row mt={{ default: 3 }}>
            <Div>
              Property Management Invite link: <a href={invite}>{invite}</a>
            </Div>
          </Row>
          <Row mt={{ default: 3 }} justifyContent="center">
            <Div width={{ default: 1 / 3 }}>
              <Button text="OK" onClick={clickConfirmHandler} />
            </Div>
          </Row>
        </Container>
      )}
    </Div>
  );
};

export default CreateYardiCompanyForm;
