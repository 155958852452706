import { useState } from "react";
import Div from "../../../../../components/baseComponents/Div";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import Row from "../../../../../components/baseComponents/Row";
import YardiLogo from "./YardiLogo";
import ToggleButtonSmall from "../../../../../components/baseComponents/ToggleButtonSmall";
import HorizontalDivider from "../../../../../components/baseComponents/HorizontalDivider";
import Modal from "../../../../../components/baseComponents/Modal";
import { currencyFormatter } from "../../../../../components/global/utils";

const yardiRentableChargeCodes = [
  "SecDep-A",
  "security",
  "rntbdepo",
  "deposit",
  "comdep",
];
interface Props {
  ledgerData: GenericObject;
  modalOpen: boolean;
  setModalOpen: Function;
}

const StyledTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  white-space: pre;
`;

const StyledTile = styled(Div)`
  max-height: 60vh;
  font-size: ${(props) => props.theme.font_size.bodyMD};
  overflow-x: scroll;
  overflow-y: scroll;
  padding-bottom: 1rem;
`;

const TableStyle = css`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-collapse: collapse;
  padding-left: 7px;
  padding-right: 7px;
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledTableRow = styled.tr`
  table-layout: fixed;
`;

const StyledTableHeader = styled.th`
  ${TableStyle}
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.white};
`;
const StyledTableSection = styled.td`
  ${TableStyle}
`;
interface TransactionHighlightProps {
  variant: string;
}
const TransactionHighlight = styled.td<TransactionHighlightProps>`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  ${TableStyle}
  ${(props) =>
    variant({
      variants: {
        charge: {
          backgroundColor: props.theme.colors.nucleusYellow,
        },
        payment: {
          backgroundColor: props.theme.colors.lightblue,
        },
      },
    })}
`;

const RentableChargeCodeHighlight = styled.td`
  ${TableStyle}
  background-color: ${(props) => props.theme.colors.rentableGreen};
`;

const TenantLedgerModal = ({ ledgerData, modalOpen, setModalOpen }: Props) => {
  const [showJson, setShowJson] = useState(false);
  let runningBalance = 0;

  return (
    <Modal
      showModal={modalOpen}
      closeModal={() => {
        setModalOpen(false);
        setShowJson(false);
      }}
      sizeVariant="big"
    >
      <Div justifyContent="center" width={{ default: 11 / 12 }}>
        <Row justifyContent="center" mt={{ default: 2 }}>
          <Div>
            <YardiLogo mt={{ default: 3 }} mb={{ default: 2 }} />
          </Div>
        </Row>
        <Div
          mt={{ default: 3 }}
          mb={{ default: 1 }}
          justifyContent="flex-start"
        >
          <Row addSpace={false} mb={{ default: 2 }}>
            <StyledTitle width={{ default: 4 / 12 }} alignItems="flex-start">
              TenantID: {ledgerData.tenant_id}
            </StyledTitle>
            <StyledTitle width={{ default: 4 / 12 }} alignItems="flex-start">
              Yardi Property: {ledgerData.yardi_property}
            </StyledTitle>
            <StyledTitle width={{ default: 4 / 12 }} alignItems="flex-start">
              Current Balance: {currencyFormatter.format(runningBalance)}
            </StyledTitle>
          </Row>
          <Row addSpace={false} mb={{ default: 2 }}>
            <StyledTitle width={{ default: 4 / 12 }} alignItems="flex-start">
              Tenant Status: {ledgerData.tenant_status}
            </StyledTitle>
            <StyledTitle width={{ default: 4 / 12 }} alignItems="flex-start">
              Yardi Unit: {ledgerData.yardi_unit}
            </StyledTitle>
            <StyledTitle width={{ default: 4 / 12 }}>
              <Row addSpace={false}>
                <Div width={{ default: 6 / 12 }}>Show Raw Data:</Div>
                <Div width={{ default: 6 / 12 }}>
                  <ToggleButtonSmall
                    initialToggleState={showJson}
                    onToggle={() => {
                      setShowJson((prevState) => !prevState);
                    }}
                  />
                </Div>
              </Row>
            </StyledTitle>
          </Row>
          <Row addSpace={false} mb={{ default: 2 }}>
            <StyledTitle width={{ default: 4 / 12 }} alignItems="flex-start">
              Tenant Email: {ledgerData.tenant_email}
            </StyledTitle>
          </Row>
        </Div>
        <HorizontalDivider />
        {showJson ? (
          <StyledTile width={{ default: 1 }} alignItems="center">
            <pre>{ledgerData.json_pretty}</pre>
          </StyledTile>
        ) : (
          <StyledTile width={{ default: 1 }}>
            <table>
              <StyledTableRow>
                <StyledTableHeader>ID</StyledTableHeader>
                <StyledTableHeader>Date</StyledTableHeader>
                <StyledTableHeader>Type</StyledTableHeader>
                <StyledTableHeader>Charge Code</StyledTableHeader>
                <StyledTableHeader>Amount</StyledTableHeader>
                <StyledTableHeader>Running Balance</StyledTableHeader>
                <StyledTableHeader>Is Open</StyledTableHeader>
                <StyledTableHeader>Is Posted</StyledTableHeader>
                <StyledTableHeader>Description</StyledTableHeader>
                <StyledTableHeader>Notes</StyledTableHeader>
              </StyledTableRow>
              {ledgerData.transactions && ledgerData.transactions.length > 0 ? (
                ledgerData.transactions.map((row: GenericObject) => {
                  const isCharge = row.TransType == "Charge";
                  const isRntbdepo = yardiRentableChargeCodes.includes(
                    row.ChargeCode
                  );
                  runningBalance = isCharge
                    ? runningBalance + Number(row.ChargeAmount)
                    : runningBalance - Number(row.ChargeAmount);
                  return (
                    <StyledTableRow>
                      <StyledTableSection>{row.TransID}</StyledTableSection>
                      <StyledTableSection>{row.TransDate}</StyledTableSection>
                      <TransactionHighlight
                        variant={isCharge ? "charge" : "payment"}
                      >
                        {row.TransType}
                      </TransactionHighlight>
                      {isRntbdepo ? (
                        <RentableChargeCodeHighlight>
                          {row.ChargeCode}
                        </RentableChargeCodeHighlight>
                      ) : (
                        <StyledTableSection>
                          {row.ChargeCode}
                        </StyledTableSection>
                      )}
                      <StyledTableSection>
                        {currencyFormatter.format(row.ChargeAmount)}
                      </StyledTableSection>
                      <StyledTableSection>
                        {currencyFormatter.format(runningBalance)}
                      </StyledTableSection>
                      <StyledTableSection>{row.isOpen}</StyledTableSection>
                      <StyledTableSection>{row.isPosted}</StyledTableSection>
                      <StyledTableSection>{row.Description}</StyledTableSection>
                      <StyledTableSection>{row.Notes}</StyledTableSection>
                    </StyledTableRow>
                  );
                })
              ) : (
                <Div>No transactions exist for tenant.</Div>
              )}
            </table>
          </StyledTile>
        )}
      </Div>
    </Modal>
  );
};

export default TenantLedgerModal;
