import styled from "styled-components";
import { useRef, useState, useEffect, ChangeEvent } from "react";
import { variant } from "styled-system";

import { COLORS } from "../global/colors";
import { Option } from "../global/ModelInterfaces";
import { orderSearch } from "../global/utils";
import VectorIcon from "../../images/VectorIcon";
import Div from "./Div";
import Row from "./Row";

interface StyledInputProps {
  onBlur?: React.MouseEventHandler<HTMLDivElement>;
  error?: boolean;
}
const StyledDropdown = styled(Div)`
  padding-bottom: 1em;
`;

const StyledName = styled.div`
  font-weight: bold;
`;

const StyledTextInput = styled.input<StyledInputProps>`
  background-color: ${(props) => props.theme.colors.grey5};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLORS.grey15};
  }
  display: flex;
  padding: 1rem 1.75rem;
  height: 3.25em;
  left: 1em;
  top: 1.625em;
  border-radius: ${(props) => props.theme.border_radius.SM};
  ${variant({
    prop: "error",
    variants: {
      true: {
        backgroundColor: COLORS.backgroundWarning,
      },
    },
  })}
`;

const StyledMenu = styled(Div)`
  padding: 1em;
`;

const StyledOption = styled(Div)`
  border-bottom: 1px solid black;
  padding: 0.5em;
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundPrimary};
    cursor: pointer;
  }
`;

interface Props {
  name: string;
  onClick: (option: any) => void;
  options: Option[];
  placeholder: string;
  value?: Option | null;
  error?: boolean;
  limitedTo?: number;
}

const AutocompleteDropdown = ({
  name,
  onClick,
  options,
  placeholder,
  value,
  error = false,
  limitedTo = 10,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [textValue, setTextValue] = useState("");

  const optionOnClick = (option: any) => {
    onClick(option);
    setIsMenuOpen(false);
  };

  const filterOptions = (searchTerm: string) => {
    onClick(null);
    if (searchTerm === "") return setFilteredOptions(options);

    const newFilteredOptions = options.filter((option: Option) => {
      return orderSearch(option.label, searchTerm);
    });

    setFilteredOptions(newFilteredOptions);
  };

  const handleTextUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value);
    filterOptions(e.target.value);
  };

  const showMenu = () => {
    return isMenuOpen && !!textValue;
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutsideMenu = (event: any) => {
      if (ref.current) {
        setIsMenuOpen(ref.current.contains(event.target));
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [ref]);

  return (
    <div ref={ref}>
      <StyledDropdown width={{ default: 1 }}>
        <StyledName>{name}</StyledName>
        <StyledTextInput
          id={"AutocompleteDropdown"}
          type={"text"}
          placeholder={value ? value.label : placeholder}
          onChange={handleTextUpdate}
          ref={inputRef}
          error={error}
        />
        {showMenu() && (
          <StyledMenu>
            {filteredOptions.slice(0, limitedTo)?.map((option: any) => {
              return (
                <StyledOption
                  key={option.id}
                  onClick={() => {
                    optionOnClick(option);
                    if(inputRef.current){
                      inputRef.current.value = "";
                    }
                  }}
                  width={{ default: 1 }}
                >
                  <Row key={option.id} justifyContent="space-between">
                    <div>{option.label}</div>
                    {value?.label === option.label ? (
                      <div>
                        <VectorIcon />
                      </div>
                    ) : null}
                  </Row>
                </StyledOption>
              );
            })}
          </StyledMenu>
        )}
      </StyledDropdown>
    </div>
  );
};

export default AutocompleteDropdown;
