import styled from "styled-components";
import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import Div from "../../components/baseComponents/Div";
import Row from "../../components/baseComponents/Row";
import {
  GenericObject,
  NucleusPrepayment,
} from "../../components/global/ModelInterfaces";
import { retrievePrepaymentDetails } from "../utils/prepaymentUtils";
import ErrorMessage from "../../components/baseComponents/ErrorMessage";
import GeneralInfoCard from "../components/PrepaymentOverviewSections/GeneralInfo";
import DepositInfoCard from "../components/PrepaymentOverviewSections/DepositInfo";
import PaymentInfoCard from "../components/PrepaymentOverviewSections/PaymentInfo";
import AdditionalFundsCard from "../components/PrepaymentOverviewSections/AdditionalFundsInfo";
import PaymentPlanInfoCard from "../components/PrepaymentOverviewSections/PaymentPlanInfo";
import CurrentBankInfoCard from "../components/PrepaymentOverviewSections/CurrentBankInfo";
import InviteInfoCard from "../components/PrepaymentOverviewSections/InviteInfo";
import PartialRefundInfoCard from "../components/PrepaymentOverviewSections/PartialRefundInfo";
import DeductionsInfo from "../components/PrepaymentOverviewSections/DeductionsInfo";
import DisbursementInfoCard from "../components/PrepaymentOverviewSections/DisbursementInfo";
import OnboardingInfo from "../components/PrepaymentOverviewSections/OnboardingInfo";
import OverviewHeader from "../components/OverviewHeader";
import NucleusLoader from "../components/NucleusLoader";
import TabSection from "../components/TabSection";
import Tab from "../components/TabNucleus";
import useNucleusPermissions from "../../utils/useNucleusPermissions";
import usePrepaymentFromParams from "../utils/usePrepaymentFromParams";
import prepaymentOverviewTabs from "../../enums/prepaymentOverviewTabs";
import prepaymentOverviewGeneralTabSections from "../../enums/prepaymentOverviewGeneralTabSections";
import PrepaymentIntegrationTab from "../components/PrepaymentIntegrationTab";
import PrepaymentNavbar from "../components/PrepaymentOverviewSections/PrepaymentOverviewNavbar";
import integrationType from "../../enums/integrationType";
import { nucleusUserPermissions } from "../../enums/nucleusPermissions";
import { useAppDispatch } from "../../store/hooks";
import { addPrepayment } from "../../store/nucleusPrepayments";

const StyledLoading = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineLG};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledNavBarContainer = styled(Div)`
  position: fixed;
  width: ${(props) => props.theme.space[9]};
`;

const StyledOverviewContainer = styled(Div)`
  width: ${(props) => `calc(100% - ${props.theme.space[7]})`};
  margin-left: ${(props) => props.theme.space[10]};
`;
const PrepaymentOverview = () => {
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { loadingPrepayments, prepayment } = usePrepaymentFromParams();
  const { loadingPermissions, prepaymentOverviewPermission } =
    useNucleusPermissions();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentTab, setCurrentTab] = useState(
    prepaymentOverviewTabs.GENERAL_TAB
  );
  const [currentGeneralTabSection, setCurrentGeneralTabSection] = useState(
    prepaymentOverviewGeneralTabSections.GENERAL_INFO
  );
  const updateGeneralTabSection = (sectionToRender: string) => {
    setCurrentGeneralTabSection(sectionToRender);
  };
  const renderGeneralTabSection = () => {
    switch (currentGeneralTabSection) {
      case prepaymentOverviewGeneralTabSections.GENERAL_INFO:
      default:
        return (
          <Row mt={{ default: 4 }}>
            <GeneralInfoCard generalInfo={prepayment.general_info} />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.INVITE_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <InviteInfoCard inviteInfo={prepayment.general_info} />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.DEPOSITS_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <DepositInfoCard depositInfo={prepayment.deposits_info} />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.PAYMENT_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <PaymentInfoCard paymentInfo={prepayment.payment_info} />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.PAYMENT_PLAN_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <PaymentPlanInfoCard
              paymentPlanInfo={prepayment.payment_plan_info?.payment_plan}
            />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.BANK_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <CurrentBankInfoCard bankInfo={prepayment.bank_info} />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.ADDITIONAL_FUNDS_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <AdditionalFundsCard
              additionalFundsInfo={prepayment.additional_funds_info}
              depositsInfo={prepayment.deposits_info}
            />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.PARTIAL_REFUND_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <PartialRefundInfoCard
              partialRefundInfo={prepayment.partial_refund_info}
            />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.DISBURSEMENT_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <DisbursementInfoCard
              disbursementInfo={prepayment.disbursement_info}
              prepaymentUUID={prepayment.general_info.prepayment_uuid}
            />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.DEDUCTIONS_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <DeductionsInfo deductionsInfo={prepayment.deductions_info} />
          </Row>
        );
      case prepaymentOverviewGeneralTabSections.ONBOARDING_INFO:
        return (
          <Row mt={{ default: 4 }}>
            <OnboardingInfo onboardingInfo={prepayment.onboarding_info} />
          </Row>
        );
    }
  };

  const renderCurrentTab = () => {
    switch (currentTab) {
      case prepaymentOverviewTabs.GENERAL_TAB:
      default:
        return renderGeneralTabSection();
      case prepaymentOverviewTabs.INTEGRATION_TAB:
        return (
          <PrepaymentIntegrationTab sectionType={currentGeneralTabSection} />
        );
    }
  };

  useEffect(() => {
    if (!!prepaymentUUID && !prepayment) {
      const successCallback = (prepaymentDetails: NucleusPrepayment) => {
        dispatch(addPrepayment(prepaymentDetails));
        setLoading(false);
      };
      const failureCallback = (errorMessage: string) => {
        setError(errorMessage);
        setLoading(false);
      };
      retrievePrepaymentDetails(
        prepaymentUUID,
        successCallback,
        failureCallback
      );
    } else {
      setLoading(false);
    }
  }, [prepaymentUUID]);

  if (error) {
    return (
      <Row justifyContent="center">
        <Div width={{ default: 6 / 12 }} justifyContent="center">
          <ErrorMessage>{error}</ErrorMessage>
        </Div>
      </Row>
    );
  }

  if (loading || loadingPermissions || loadingPrepayments) {
    return (
      <Row justifyContent="center">
        <StyledLoading width={{ default: 3 / 12 }}>
          <NucleusLoader />
        </StyledLoading>
      </Row>
    );
  }

  if (prepaymentOverviewPermission === nucleusUserPermissions.NONE) {
    return <Redirect to={"/nucleus/forbidden"} />;
  }

  return (
    <>
      <Row addSpace={false}>
        <StyledNavBarContainer>
          <PrepaymentNavbar
            currentSection={currentGeneralTabSection}
            onSelect={updateGeneralTabSection}
          />
        </StyledNavBarContainer>
        <StyledOverviewContainer>
          <Div width={{ default: 11 / 12 }}>
            <OverviewHeader
              overviewHeader={"Prepayment Overview"}
              overviewName={prepayment.general_info.name}
              LeftSubHeader={prepayment.general_info.address}
              rightSubHeader={prepayment.general_info.email}
              tabs={
                <TabSection>
                  <Div width={"fill"}>
                    <Tab
                      name="General"
                      active={currentTab === prepaymentOverviewTabs.GENERAL_TAB}
                      onClick={() => {
                        setCurrentTab(prepaymentOverviewTabs.GENERAL_TAB);
                      }}
                    />
                  </Div>
                  {prepayment.integration_info &&
                    prepayment.integration_info.integration_type !==
                      integrationType.NONE && (
                      <Div width={"fill"}>
                        <Tab
                          name={prepayment.integration_info.integration_name}
                          active={
                            currentTab ===
                            prepaymentOverviewTabs.INTEGRATION_TAB
                          }
                          onClick={() => {
                            setCurrentTab(
                              prepaymentOverviewTabs.INTEGRATION_TAB
                            );
                          }}
                        />
                      </Div>
                    )}
                </TabSection>
              }
            />
            {renderCurrentTab()}
          </Div>
        </StyledOverviewContainer>
      </Row>
    </>
  );
};

export default PrepaymentOverview;
