import {
  useRef,
  useState,
  ChangeEvent,
  ChangeEventHandler,
  DragEvent,
} from "react";
import { first } from "lodash";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from "../..";
import UploadIcon from "../../images/UploadIcon";
import styled from "styled-components";
import Div from "./Div";
import Row from "./Row";
import XIcon from "../../images/XIcon";
import Button from "./Button";
import BeatLoader from "react-spinners/BeatLoader";
import {
  FILE_SIZE_LIMIT_MBS,
  checkAllowedFileUpload,
  checkFileSize,
  renderFileTypeError,
} from "../../utils/documentUtils";

const StyledUploadText = styled(Div)`
  text-decoration: none;
  margin: 0.5rem 0rem;
  overflow-wrap: break-word;
`;

const StyledHiddenInput = styled.div`
  display: none;
`;

const StyledXButton = styled(Div)`
  cursor: pointer;
`;

const StyledFileUploadSpace = styled(Row)`
  border: 2px dashed ${(props) => props.theme.colors.grey};
  border-radius: ${(props) => props.theme.border_radius.SM};
  min-height: 12em;
  cursor: pointer;
`;

const StyledFileUploadTextContainer = styled(Row)`
  height: 100%;
  flex-wrap: wrap;
`;

interface Props {
  onUpload?: (e: any) => void;
  onRemove?: () => void;
  confirmUpload?: boolean;
  defaultText?: string;
}
const FileDragAndDrop = ({
  onUpload,
  onRemove,
  confirmUpload,
  defaultText,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state: RootState) => state.auth.user);
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onFileSelect: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files) {
      setError("");
      const newFile = first(e.target.files);
      if (
        newFile &&
        checkAllowedFileUpload(newFile) &&
        checkFileSize(newFile)
      ) {
        if (onUpload) onUpload(e);
        setFile(newFile);
      } else if (!checkAllowedFileUpload(newFile)) {
        setError(renderFileTypeError());
      } else if (!checkFileSize(newFile)) {
        setError(
          `Oops! File size limit exceeded. Please make sure your file is less than ${FILE_SIZE_LIMIT_MBS} MB!`
        );
      }
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      setError("");
      const newFile = first(e.dataTransfer.files);
      if (
        newFile &&
        checkAllowedFileUpload(newFile) &&
        checkFileSize(newFile)
      ) {
        if (onUpload) onUpload(e);
        setFile(newFile);
      } else if (!checkAllowedFileUpload(newFile)) {
        setError(renderFileTypeError());
      } else if (!checkFileSize(newFile)) {
        setError(
          `Oops! File size limit exceeded. Please make sure your file is less than ${FILE_SIZE_LIMIT_MBS} MB!`
        );
      }
    }
  };

  const handleUploadDocument = () => {
    if (!file) {
      setError("Oops something went wrong, please try again");
      return;
    }
    setError("");
    setUploading(true);
  };

  const reset = () => {
    setFile(null);
  };

  const getDefaultText = () => {
    return defaultText || "Drag file or click to upload";
  };

  return (
    <>
      <StyledFileUploadSpace justifyContent="center" alignItems="center">
        <div
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          onClick={() => {
            if(fileInputRef.current){
              fileInputRef.current.click()
            }}
          }
        >
          <Row justifyContent="center">{error}</Row>
          {uploading ? (
            <BeatLoader />
          ) : (
            <StyledFileUploadTextContainer
              justifyContent="center"
              alignItems="center"
            >
              {!file && (
                <Div>
                  <UploadIcon />
                </Div>
              )}
              <StyledUploadText
                width={{ sm: 4 / 12, md: 6 / 12 }}
                textAlign="center"
              >
                {file?.name || getDefaultText()}
              </StyledUploadText>

              {file && confirmUpload && (
                <Div width={{ default: 2 / 3, md: 1 / 4, lg: 1 / 4 }}>
                  <Button
                    type="primary"
                    text="Confirm Upload"
                    onClick={() => handleUploadDocument()}
                  />
                </Div>
              )}
              {file && (
                <StyledXButton
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onRemove) onRemove();
                    reset();
                  }}
                >
                  <XIcon width="32" height="32" />
                </StyledXButton>
              )}
            </StyledFileUploadTextContainer>
          )}
        </div>
        <StyledHiddenInput>
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .pdf, .csv"
            ref={fileInputRef}
            onChange={onFileSelect}
          />
        </StyledHiddenInput>
      </StyledFileUploadSpace>
    </>
  );
};

export default FileDragAndDrop;
