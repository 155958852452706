import styled from "styled-components";
import { useRef, useState, useEffect } from "react";
import { variant } from "styled-system";

import { COLORS } from "../global/colors";
import { Option } from "../global/ModelInterfaces";
import DownArrowIcon from "../../images/DownArrowIcon";
import UpArrowIcon from "../../images/UpArrowIcon";
import VectorIcon from "../../images/VectorIcon";
import Div from "./Div";
import Row from "./Row";

interface StyledInputProps {
  onBlur?: React.MouseEventHandler<HTMLDivElement>;
  error?: boolean;
}
const StyledDropdown = styled(Div)`
  padding-bottom: 1em;
`;

const StyledName = styled.span`
  font-weight: bold;
  font-size: 0.875rem;
`;

const StyledInput = styled(Div)<StyledInputProps>`
  background-color: ${(props) => props.theme.colors.grey5};
  display: flex;
  margin: 8px 0px;
  padding: 1em;
  height: 3.5em;
  left: 1em;
  top: 1.625em;
  border-radius: ${(props) => props.theme.border_radius.SM};
  ${variant({
    prop: "error",
    variants: {
      true: {
        backgroundColor: COLORS.backgroundWarning,
      },
    },
  })}
`;
const StyledMenu = styled(Div)`
  padding: 1em;
`;

const StyledOption = styled(Div)`
  border-bottom: 1px solid black;
  padding: 0.5em 0.25em;
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundPrimary};
    cursor: pointer;
  }
`;

interface Props {
  name: string;
  onClick: (option: any) => void;
  options: Option[];
  placeholder: string;
  value?: Option | null;
  error?: boolean;
}

const Dropdown = ({
  name,
  onClick,
  options,
  placeholder,
  value,
  error = false,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const openMenuOnClick = () => {
    setIsMenuOpen(true);
  };
  const optionOnClick = (option: any) => {
    onClick(option);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutsideMenu = (event: any) => {
      if (ref.current) {
        setIsMenuOpen(ref.current.contains(event.target));
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [ref]);

  return (
    <div ref={ref}>
      <StyledName>{name}</StyledName>
      <StyledDropdown width={{ default: 1 }}>
        <StyledInput
          onClick={openMenuOnClick}
          onBlur={() => setIsMenuOpen(false)}
          alignItems="center"
          error={error}
        >
          <Row justifyContent="space-between">
            {value ? <div>{value.label}</div> : <div>{placeholder}</div>}
            <div>{isMenuOpen ? <UpArrowIcon /> : <DownArrowIcon />}</div>
          </Row>
        </StyledInput>
        {isMenuOpen && (
          <StyledMenu>
            {options.map((option: any) => (
              <StyledOption
                key={option.id}
                onClick={() => optionOnClick(option)}
                width={{ default: 1 }}
              >
                <Row
                  key={option.id}
                  justifyContent="space-between"
                  flexWrap="nowrap"
                >
                  <div>{option.label}</div>
                  {value?.label === option.label ? (
                    <div>
                      <VectorIcon />
                    </div>
                  ) : null}
                </Row>
              </StyledOption>
            ))}
          </StyledMenu>
        )}
      </StyledDropdown>
    </div>
  );
};

export default Dropdown;
