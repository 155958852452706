import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import { GenericObject } from "../../../components/global/ModelInterfaces";
import {
  dateShortFormatter,
  currencyFormatter,
} from "../../../components/global/utils";

interface Props {
  deposit: GenericObject;
}

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;
const DepositSection = ({ deposit }: Props) => {
  return (
    <Div>
      <Row>
        <StyledLabel>UUID:</StyledLabel>
        {deposit.uuid}
      </Row>
      <Row>
        <StyledLabel>Account ID:</StyledLabel>
        {deposit.account_id}
      </Row>
      <Row>
        <StyledLabel>ID:</StyledLabel>
        {deposit.id}
      </Row>
      <Row>
        <StyledLabel>Deleted At:</StyledLabel>
        {deposit.deleted_at ? dateShortFormatter(deposit.deleted_at) : " N/A"}
      </Row>
      <Row>
        <StyledLabel>Prepayment ID:</StyledLabel>
        {deposit.prepayment}
      </Row>
      <Row>
        <StyledLabel>Amount:</StyledLabel>
        {currencyFormatter.format(deposit.amount)}
      </Row>
      <Row>
        <StyledLabel>Status:</StyledLabel>
        {deposit.status}
      </Row>
      <Row>
        <StyledLabel>Is Paid Monthly:</StyledLabel>
        {deposit.is_paid_monthly.toString()}
      </Row>
      <Row>
        <StyledLabel>Interest Eligible:</StyledLabel>
        {deposit.interest_eligible.toString()}
      </Row>
      <Row>
        <StyledLabel>Total Accrued Interest: </StyledLabel>
        {currencyFormatter.format(deposit.total_accrued_interest)}
      </Row>
      <Row>
        <StyledLabel>Latest Disbursal Amount:</StyledLabel>{" "}
        {deposit.latest_interest_disbursal_amount
          ? currencyFormatter.format(deposit.latest_interest_disbursal_amount)
          : " N/A"}
      </Row>
      <Row>
        <StyledLabel>Latest Disbursal Date:</StyledLabel>{" "}
        {deposit.latest_interest_disbursal_date
          ? dateShortFormatter(deposit.latest_interest_disbursal_date)
          : " N/A"}
      </Row>
      <Row>
        <StyledLabel>Dwolla Fee: </StyledLabel>
        {currencyFormatter.format(deposit.dwolla_fee)}
      </Row>
    </Div>
  );
};

export default DepositSection;
