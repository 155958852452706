import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { variant } from "styled-system";

interface StyleProps {
  variant?: string;
  error?: boolean;
  heightVariant?: string;
  focused?: boolean;
}

const StyledTextArea = styled.textarea<StyleProps>`
  border-radius: ${(props) => props.theme.border_radius.SM};
  border: none;
  padding: 1rem;
  display: inline;
  width: 100%;
  font-family: monospace;
  resize: vertical;
  min-height: 12.5rem;
  ${(props) =>
    variant({
      prop: "variant",
      variants: {
        light: {
          backgroundColor: props.theme.colors.grey5,
          color: props.theme.colors.grey60,
          "::placeholder": {
            color: props.theme.colors.grey60,
          },
        },
        dark: {
          backgroundColor: props.theme.colors.grey80,
          color: props.theme.colors.grey20,
          "::placeholder": {
            color: props.theme.colors.grey20,
          },
        },
      },
    })};
  ${(props) =>
    variant({
      prop: "error",
      variants: {
        true: {
          backgroundColor: props.theme.colors.backgroundWarning,
        },
      },
    })};
  ${(props) =>
    variant({
      prop: "heightVariant",
      variants: {
        default: {
          height: "3.5rem",
        },
        short: {
          height: "2.5rem",
        },
        tiny: {
          height: "1rem",
        },
        tall: {
          height: "25rem",
        },
      },
    })};
`;

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const StyledHelpText = styled.span`
  font-size: ${(props) => props.theme.font_size.bodyMD};
  color: ${(props) => props.theme.colors.grey60};
`;

interface Props {
  placeholderText?: string;
  defaultValue?: string;
  helpText?: string;
  backgroundColor?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  value?: string;
  className?: string;
  error?: boolean;
  readOnly?: boolean;
  heightVariant?: string;
}

const TextArea = ({
  placeholderText,
  defaultValue,
  helpText,
  label,
  backgroundColor,
  onChange,
  onBlur,
  onKeyDown,
  value,
  className,
  error = false,
  readOnly = false,
  heightVariant = "default",
}: Props) => {
  const textAreaRef = useRef<HTMLDivElement | null>(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const handleTextAreaScroll = (event: any) => {
      event.target.blur();
    };

    document.addEventListener("wheel", handleTextAreaScroll);
    return () => {
      document.removeEventListener("wheel", handleTextAreaScroll);
    };
  }, []);

  return (
    <div ref={textAreaRef}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledTextArea
        variant={backgroundColor || "light"}
        placeholder={placeholderText}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        error={error}
        className={className}
        focused={focused}
        onFocus={() => setFocused(true)}
        onBlur={(e) => {
          setFocused(false);
          onBlur && onBlur(e);
        }}
        onKeyDown={(e) => e.key === "Enter" && onKeyDown && onKeyDown(e)}
        heightVariant={heightVariant}
        readOnly={readOnly}
      />
      {helpText && <StyledHelpText>{helpText}</StyledHelpText>}
    </div>
  );
};

export default TextArea;
