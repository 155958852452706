import Dropdown from "../../../../../components/baseComponents/Dropdown";
import { Option } from "../../../../../components/global/ModelInterfaces";

const resourceTypes: Option[] = [
  {
    id: "resident-data",
    label: "ItfResidentData",
    identifier: "ItfResidentData",
  },
  {
    id: "resident-transactions",
    label: "ItfResidentTransactions20",
    identifier: "ItfResidentTransactions20",
  },
];

interface Props {
  value: Option | null;
  onChange: (value: Option | null) => void;
}

const ResourceTypeSelect = ({ value, onChange }: Props) => {
  return (
    <Dropdown
      name="Resource Type"
      value={value}
      onClick={onChange}
      options={resourceTypes}
      placeholder="Select a resource type"
    />
  );
};

export default ResourceTypeSelect;
