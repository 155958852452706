import axios from "axios";
import { GenericObject } from "../../components/global/ModelInterfaces";

export const rentManagerSecurityDepositsHeldHeaders = [
  "Tenant Email",
  "Tenant First Name",
  "Tenant Last Name",
  "Tenant Phone Number",
  "Rent Manager Lease Unit ID",
  "Rent Manager Charge Unit ID",
  "Rentable Unit UUID",
  "Rent Manager Location",
  "Address",
  "Lease Start Date",
  "Lease End Date",
  "Security Deposit Amount",
  "Rent Manager Tenant ID",
  "Rent Manager Property ID",
  "Rent Manager Property Name",
];

// Company Util Functions
export const createRentManagerCompany = (
  companyUUID: string,
  companyCode: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/create-rent-manager-company";
  axios
    .post(url, {
      company_uuid: companyUUID,
      company_code: companyCode,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const getRentManagerCompanyDetails = (
  companyUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/get-details";

  axios
    .post(url, {
      company_uuid: companyUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch(() => {
      failureCallback();
    });
};

export const createLocationUserDefinedFields = (
  locationUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/create-location-user-defined-fields";

  axios
    .post(url, {
      location_uuid: locationUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const createLocationRentableTrustAccount = (
  locationUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url =
    "/nucleus/api/rent-manager/create-location-rentable-trust-account";
  axios
    .post(url, {
      location_uuid: locationUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const createLocationRentableDeductionsChargeType = (
  locationUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url =
    "/nucleus/api/rent-manager/create-location-rentable-deductions-charge-type";

  axios
    .post(url, {
      location_uuid: locationUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const inviteProspect = (
  locationUUID: string,
  prospectEmail: string,
  prospectID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/invite-prospect";
  const body: GenericObject = {
    location_uuid: locationUUID,
    prospect_email: prospectEmail,
  };

  if (prospectID) {
    body["prospect_id"] = prospectID;
  }

  axios
    .post(url, body)
    .then(() => {
      successCallback(prospectEmail, prospectID);
    })
    .catch((res) => {
      failureCallback(res.data.message, res.data.error_details);
    });
};

export const retrieveResourceID = (
  locationUUID: string,
  email: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/retrieve-resource-id";
  axios
    .post(url, {
      location_uuid: locationUUID,
      email: email,
    })
    .then((res) => {
      successCallback(res.data.resource_list);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const retrieveResourceList = (
  locationUUID: string,
  resourceURL: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/retrieve-resource-list";
  axios
    .post(url, {
      location_uuid: locationUUID,
      resource_url: resourceURL,
    })
    .then((res) => {
      successCallback(res.data.resource_list);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const createDepositChargeTransaction = (
  prepaymentUUID: string,
  ignoreManagementFeeBackDate: boolean,
  ignoreHardCloseDate: boolean,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/create-deposit-charge-transaction";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      ignore_management_fee_backdate: ignoreManagementFeeBackDate,
      ignore_hard_close_date: ignoreHardCloseDate,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};
export const createDepositPaymentTransaction = (
  prepaymentUUID: string,
  ignoreManagementFeeBackDate: boolean,
  ignoreHardCloseDate: boolean,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/create-deposit-payment-transaction";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      ignore_management_fee_backdate: ignoreManagementFeeBackDate,
      ignore_hard_close_date: ignoreHardCloseDate,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};
export const createRentableTrustBankDeposit = (
  prepaymentUUID: string,
  ignoreManagementFeeBackDate: boolean,
  ignoreHardCloseDate: boolean,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/create-rentable-trust-bank-deposit";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      ignore_management_fee_backdate: ignoreManagementFeeBackDate,
      ignore_hard_close_date: ignoreHardCloseDate,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const syncLocationPropertiesAndUnits = (
  locationUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/sync-location-properties-and-units";
  axios
    .post(url, {
      location_uuid: locationUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch(() => {
      failureCallback("There was an error syncing Properties and Units.");
    });
};

export const createAssociatedRentManagerPrepayment = (
  rentManagerResourceID: string,
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/link-existing-prepayment";
  axios
    .post(url, {
      rent_manager_resource_id: rentManagerResourceID,
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((error) => {
      failureCallback(
        `There was an error creating an associated Rent Manager Prepayment. ${error.data}`
      );
    });
};

// Prepayment Util Functions
export const getPrepaymentDetails = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/get-prepayment-details";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const getUserDefinedValues = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/get-user-defined-values";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const getRefundDetails = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/get-refund-details";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const createTenantRefundTransactions = (
  prepaymentUUID: string,
  useOpenCharges: boolean,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/create-tenant-refund-transactions";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      use_open_charges: useOpenCharges,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const createJournalEntry = (
  depositUUID: string,
  locationUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/create-journal-entry";
  axios
    .post(url, {
      deposit_uuid: depositUUID,
      location_uuid: locationUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const getSecurityDepositChargeTransaction = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/get-deposit-charge-transaction";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const getSecurityDepositPaymentTransaction = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/get-deposit-payment-transaction";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const getRentableTrustDepositTransaction = (
  prepaymentUUID: string,
  paymentID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url =
    "/nucleus/api/rent-manager/get-rentable-trust-bank-deposit-transaction";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
      payment_transaction_id: paymentID,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const updatePrepaymentDetails = (
  prepaymentUUID: string,
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/update-prepayment-details";
  axios
    .post(url, {
      prepayment_uuid: prepaymentUUID,
    })
    .then((res) => {
      successCallback(
        res.data.rent_manager_prepayment_details,
        res.data.prepayment_general_info
      );
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};

export const getRentManagerFailedInvites = (
  successCallback: Function,
  failureCallback: Function
) => {
  const url = "/nucleus/api/rent-manager/get-failed-invites";
  axios
    .get(url)
    .then((res) => {
      successCallback(res.data.failed_invites);
    })
    .catch((res) => {
      failureCallback(res.data);
    });
};
