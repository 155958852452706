import { useState, useEffect } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import { useParams } from "react-router-dom";
import usePrepaymentFromParams from "../../../../utils/usePrepaymentFromParams";
import styled from "styled-components";
import { some } from "lodash";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import BarLoader from "../../../../../components/baseComponents/BarLoader";
import prepaymentStatuses from "../../../../../enums/prepaymentStatuses";
import {
  currencyFormatter,
  dateShortFormatter,
} from "../../../../../components/global/utils";
import { updatePrepaymentIntegrationInfo } from "../../../../../store/nucleusPrepayments";
import {
  GenericObject,
  Deposit,
} from "../../../../../components/global/ModelInterfaces";
import {
  getRefundDetails,
  createTenantRefundTransactions,
  createJournalEntry,
} from "../../../../utils/rentManagerUtils";

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const DepositRefundSection = () => {
  const dispatch = useAppDispatch();
  const { prepaymentUUID } = useParams<GenericObject>();
  const { prepayment } = usePrepaymentFromParams();

  const [loadingRefundInfo, setLoadingRefund] = useState(true);
  const [error, setError] = useState("");

  const depositIsDisbursingOrClosed = (deposit: Deposit) => {
    if (!deposit) {
      return false;
    }

    return [prepaymentStatuses.DISBURSING, prepaymentStatuses.CLOSED].includes(
      deposit.status_id
    );
  };

  const handleCreateRefundTransactions = () => {
    setLoadingRefund(true);
    setError("");

    if (!some(prepayment.integration_info.user_defined_values.tenantValues)) {
      setError(
        "The Tenant User Defined values for this prepayment were not loaded properly, please refresh the page and try again."
      );
      setLoadingRefund(false);
      return;
    }
    const useOpenCharges =
      prepayment.integration_info.user_defined_values.tenantValues
        .rentableSecurityDepositUseOpenCharges === "Yes";
    const successCallback = (refundInfo: GenericObject) => {
      setLoadingRefund(false);
      dispatch(
        updatePrepaymentIntegrationInfo(
          prepaymentUUID,
          "refund_info",
          refundInfo
        )
      );
    };
    const failureCallback = (errorMessage: string) => {
      setError(errorMessage);
      setLoadingRefund(false);
    };
    createTenantRefundTransactions(
      prepaymentUUID,
      useOpenCharges,
      successCallback,
      failureCallback
    );
  };

  const handleCreateJournalEntry = () => {
    setLoadingRefund(true);
    setError("");

    if (!some(prepayment.integration_info.user_defined_values.tenantValues)) {
      setError(
        "The Tenant User Defined values for this prepayment were not loaded properly, please refresh the page and try again."
      );
      setLoadingRefund(false);
      return;
    }
    const successCallback = (journalDetails: GenericObject) => {
      const updatedRefundInfo = {
        ...prepayment.integration_info.refund_info,
        journal_entry_details: journalDetails,
      };
      setLoadingRefund(false);
      dispatch(
        updatePrepaymentIntegrationInfo(
          prepaymentUUID,
          "refund_info",
          updatedRefundInfo
        )
      );
    };
    const failureCallback = (errorMessage: string) => {
      setError(errorMessage);
      setLoadingRefund(false);
    };
    createJournalEntry(
      prepayment.deposits_info.security_deposit.uuid,
      prepayment.integration_info.details.rent_manager_location.uuid,
      successCallback,
      failureCallback
    );
  };

  const renderCreateRefundButton = () => {
    return (
      depositIsDisbursingOrClosed(prepayment.deposits_info.security_deposit) &&
      Number(
        prepayment.integration_info.refund_info.refund_transaction_details
          .security_deposit_held
      ) > 0 &&
      !prepayment.integration_info.refund_info.refund_transaction_details
        .transaction_id
    );
  };

  const renderCreateJournalButton = () => {
    const totalDeductionAmount = prepayment.disbursement_info
      ? prepayment.disbursement_info.withheld_amount
      : 0;
    const isFullDeduction =
      totalDeductionAmount == prepayment.deposits_info.security_deposit.amount;
    return (
      (prepayment.integration_info.refund_info.refund_transaction_details
        .refund_transaction_id ||
        (isFullDeduction &&
          depositIsDisbursingOrClosed(
            prepayment.deposits_info.security_deposit
          ))) &&
      totalDeductionAmount > 0 &&
      !prepayment.integration_info.refund_info.journal_entry_details.journal_id
    );
  };

  const getPrepaymentRefundDetails = () => {
    setLoadingRefund(true);
    const successCallback = (refundInfo: GenericObject) => {
      dispatch(
        updatePrepaymentIntegrationInfo(
          prepaymentUUID,
          "refund_info",
          refundInfo
        )
      );
      setLoadingRefund(false);
    };
    const failureCallback = (errorMessage: string) => {
      setError(errorMessage);
      setLoadingRefund(false);
    };
    getRefundDetails(prepaymentUUID, successCallback, failureCallback);
  };

  useEffect(() => {
    if (!prepayment.integration_info.details) {
      getPrepaymentRefundDetails();
    }
  }, []);

  if (loadingRefundInfo) {
    return (
      <Row justifyContent="center" mb={{ default: 5 }}>
        <BarLoader loadingText="Loading Refund Details" />
      </Row>
    );
  }

  return (
    <DropdownCard title={"Deposit Refund Details"} initiallyOpen={false}>
      {error && (
        <Row justifyContent="center">
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
      <Row>
        <Div width={"fill"}>
          <Div>
            <h4>Tenant Refund</h4>
          </Div>
          <Row>
            <StyledLabel>RM Security Deposit Held:</StyledLabel>
            {currencyFormatter.format(
              prepayment.integration_info.refund_info.refund_transaction_details
                .security_deposit_held
            )}
          </Row>
          <Row>
            <StyledLabel>RM Refund Transaction ID:</StyledLabel>
            {prepayment.integration_info.refund_info.refund_transaction_details
              .refund_transaction_id
              ? prepayment.integration_info.refund_info
                  .refund_transaction_details.refund_transaction_id
              : "N/A"}
          </Row>
          <Row>
            <StyledLabel>RM Refund Transaction Date:</StyledLabel>
            {prepayment.integration_info.refund_info.refund_transaction_details
              .refund_transaction_date
              ? dateShortFormatter(
                  prepayment.integration_info.refund_info
                    .refund_transaction_details.refund_transaction_date
                )
              : "N/A"}
          </Row>
          {renderCreateRefundButton() && (
            <Div width={{ default: 1 }}>
              <Div mb={{ default: 2 }} mt={{ default: 4 }}>
                This tenant's security deposit is either disbursing or has been
                closed, but the Rent Manager refund did not get created. Use the
                button below to create the refund transactions in the Rent
                Manager System.
              </Div>
              <Button
                onClick={handleCreateRefundTransactions}
                loading={loadingRefundInfo}
                text="Process Rent Manager Refund"
              />
            </Div>
          )}
        </Div>
        {!prepayment.integration_info.refund_info.journal_entry_details
          .journal_creation_disabled ? (
          <Div width={"fill"}>
            <Div>
              <h4>Journal Entry</h4>
            </Div>
            <Row>
              <StyledLabel>Journal Entry ID:</StyledLabel>
              {prepayment.integration_info.refund_info.journal_entry_details
                .journal_id
                ? prepayment.integration_info.refund_info.journal_entry_details
                    .journal_id
                : "N/A"}
            </Row>
            <Row>
              <StyledLabel>Journal Entry Created:</StyledLabel>
              {prepayment.integration_info.refund_info.journal_entry_details
                .journal_creation_date
                ? dateShortFormatter(
                    prepayment.integration_info.refund_info
                      .journal_entry_details.journal_creation_date
                  )
                : "N/A"}
            </Row>
            <Row>
              <StyledLabel>Journal Entry Amount:</StyledLabel>
              {prepayment.integration_info.refund_info.journal_entry_details
                .journal_amount
                ? currencyFormatter.format(
                    prepayment.integration_info.refund_info
                      .journal_entry_details.journal_amount
                  )
                : "N/A"}
            </Row>
            {renderCreateJournalButton() && (
              <Div width={{ default: 1 }}>
                <Div mb={{ default: 2 }} mt={{ default: 4 }}>
                  This deposit's refund transaction was posted back to Rent
                  Manager, but the journal entry creation step failed. Use the
                  button below to create a journal entry out of Rentable Trust.
                </Div>
                <Button
                  onClick={handleCreateJournalEntry}
                  loading={loadingRefundInfo}
                  text="Create Refund Journal Entry"
                />
              </Div>
            )}
          </Div>
        ) : (
          <Div width={"fill"}>
            <Div>
              <h4>Journal Entry - Disabled</h4>
            </Div>
            {
              prepayment.integration_info.refund_info.journal_entry_details
                .journal_creation_disabled
            }
          </Div>
        )}
      </Row>
    </DropdownCard>
  );
};

export default DepositRefundSection;
