import styled from "styled-components";
import Button from "./Button";
import CopyIcon from "../../images/CopyIcon";

const StyledButton = styled(Button)`
  position: absolute;
  top: 2rem;
  right: 0.5rem;
  padding: 0.25rem;
  min-width: auto;
  width: auto;
  height: 1.75rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  z-index: 1;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.25rem;
  white-space: nowrap;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

interface Props {
  onClick: () => void;
  copySuccess: boolean;
}

const CopyButton = ({ onClick, copySuccess }: Props) => {
  return (
    <StyledButton onClick={onClick} type="secondary">
      <ButtonContent>
        <CopyIcon width="16" height="16" />
        {copySuccess ? "Copied!" : "Copy"}
      </ButtonContent>
    </StyledButton>
  );
};

export default CopyButton;
