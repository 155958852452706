export const COLORS = {
  black: "#000",
  navy: "#2F374B",
  primary: "#1EB88D",
  rentableGreen: "#1EB88D", // same as primary, naming to match Nucleus colors for shared components
  primary_transparent: "rgba(30, 184, 141, 0.2)",
  primary25: "#BCEADD",
  backgroundPrimary: "#D2F1E8",
  hoverGreen: "#28DDAA",
  clickGreen: "#1A9E79",
  white: "#FFFFFF",
  grey: "#222A3E",
  grey80: "#4E5464",
  grey60: "#7A7F8B",
  grey40: "#A7AAB2",
  grey20: "#D3D4D8",
  grey15: "#4f4f4f",
  grey10: "#E9EAEC",
  grey5: "#F4F4F5",
  grey2: "#F8F8F9",
  neutral0: "hsl(0, 0%, 100%)",
  neutral5: "hsl(0, 0%, 95%)",
  neutral10: "hsl(0, 0%, 90%)",
  neutral20: "hsl(0, 0%, 80%)",
  neutral30: "hsl(0, 0%, 70%)",
  neutral40: "hsl(0, 0%, 60%)",
  neutral50: "hsl(0, 0%, 50%)",
  neutral60: "hsl(0, 0%, 40%)",
  neutral70: "hsl(0, 0%, 30%)",
  neutral80: "hsl(0, 0%, 20%)",
  neutral90: "hsl(0, 0%, 10%)",
  backgroundSecondary: "#F8F8F9",
  backgroundTertiary: "#2F374B",
  boxShawdowGrey: "#0000001F",
  hoverGrey: "rgba(148, 150, 154, 0.1)",
  clickGrey: "rgba(47, 55, 75, 0.2)",
  outlineGrey: "#BDBDC0",
  blue: "#3467BC",
  purple: "#3F40F1",
  teal: "#29EFE1",
  pink: "#CFA0FD",
  yellow: "#FEE44C",
  backgroundYellow: "#FFEF8F",
  danger: "#F13F4A",
  success: "#155724",
  warning: "#EA9C29",
  backgroundDanger: "#f05660",
  backgroundDisabled: "#D3D4D8",
  backgroundWarning: "#FFEAAD",
  disabledText: "#7A7F8B",
  dropdownCaret: "#222A3E",
};
