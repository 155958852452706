import { useEffect, useState } from "react";
import Div from "./Div";
import DownCaret from "../../images/DownCaret";
import UpCaret from "../../images/UpCaret";

interface Props {
  open: boolean;
  style?: string;
  onToggle?: Function;
}

const DropdownCaret = ({ open, style, onToggle }: Props) => {
  const [openCaret, setOpenCaret] = useState(false);

  useEffect(() => {
    setOpenCaret(open);
  }, [open]);

  const changeState = () => {
    setOpenCaret((oldState) => !oldState);
  };
  const handleToggle = () => {
    changeState();
    if (onToggle !== undefined) onToggle();
  };

  return (
    <Div onClick={handleToggle}>{openCaret ? <UpCaret /> : <DownCaret />}</Div>
  );
};

export default DropdownCaret;
