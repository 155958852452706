import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { Option } from "../global/ModelInterfaces";

interface AllowedRequestTypes {
  [key: string]: string[];
}

const ALLOWED_REQUEST_TYPES: AllowedRequestTypes = {
  ItfResidentData: [
    "GetResidents",
    "GetTenantStatus",
    "GetResidentData_ByChargeCode",
    "GetResidentsByStatus",
    "GetResidentData",
  ],
  ItfResidentTransactions20: [
    "GetUnitInformation_Login",
    "GetResidentTransaction_Login",
    "GetPropertyConfigurations",
    "GetPayables",
    "ExportChartOfAccounts",
  ],
};

interface Props {
  resourceType: string;
  onSelectRequestType: (requestType: string) => void;
  defaultValue?: string;
}

const RequestTypeSelect = ({
  resourceType,
  onSelectRequestType,
  defaultValue,
}: Props) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  useEffect(() => {
    if (resourceType && ALLOWED_REQUEST_TYPES[resourceType]) {
      const requestTypes = ALLOWED_REQUEST_TYPES[resourceType];
      const formattedOptions = requestTypes.map((requestType: string) => ({
        id: requestType,
        label: requestType,
        identifier: requestType,
      }));
      setOptions(formattedOptions);

      if (defaultValue) {
        const defaultOption = formattedOptions.find(
          (opt: Option) => opt.identifier === defaultValue
        );
        setSelectedOption(defaultOption || null);
      }
    } else {
      setOptions([]);
      setSelectedOption(null);
    }
  }, [resourceType, defaultValue]);

  const handleChange = (option: Option) => {
    setSelectedOption(option);
    onSelectRequestType(option.identifier || option.id);
  };

  return (
    <Dropdown
      name="Request Type"
      options={options}
      onClick={handleChange}
      placeholder="Select request type"
      value={selectedOption}
      error={false}
    />
  );
};

export default RequestTypeSelect;
