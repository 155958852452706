import { first, toArray } from "lodash";
const SET_TENANT_INFO = "tenantInfo/SET_TENANT_INFO";
const UPDATE_TENANT_ONBOARDING = "tenantInfo/UPDATE_TENANT_ONBOARDING";
const UPDATE_TENANT_OFFBOARDING = "tenantInfo/UPDATE_TENANT_OFFBOARDING";
const UPDATE_TENANT_USER_INFO = "tenantInfo/UPDATE_TENANT_USER_INFO";
const UPDATE_TENANT_CREDIT_CARDS = "tenantInfo/UPDATE_TENANT_CREDIT_CARDS";
const UPDATE_TENANT_DEPOSITS = "tenantInfo/UPDATE_TENANT_DEPOSITS";
const UPDATE_TENANT_INSURANCE = "tenantInfo/UPDATE_TENANT_INSURANCE";
const SET_DOCUMENTS = "tenantInfo/SET_DOCUMENTS";
const UPDATE_PREPAYMENT = "tenantInfo/UPDATE_PREPAYMENT";
const UPDATE_FEES = "tenantInfo/UPDATE_FEES";

export const setTenantInfo = (tenantInfo) => {
  return {
    type: SET_TENANT_INFO,
    tenantInfo,
  };
};

export const updateTenantOnboarding = (onboarding) => {
  return {
    type: UPDATE_TENANT_ONBOARDING,
    onboarding,
  };
};

export const updateTenantOffboarding = (prepaymentUUID) => {
  return {
    type: UPDATE_TENANT_OFFBOARDING,
    prepaymentUUID,
  };
};

export const updateTenantUserInfo = (personalInfo) => {
  return {
    type: UPDATE_TENANT_USER_INFO,
    personalInfo,
  };
};

export const updateTenantDeposits = (deposits) => {
  return {
    type: UPDATE_TENANT_DEPOSITS,
    deposits,
  };
};

export const updateFees = (fees) => {
  return {
    type: UPDATE_FEES,
    fees,
  };
};
export const updateTenantInsurance = (enrollment) => {
  return {
    type: UPDATE_TENANT_INSURANCE,
    enrollment,
  };
};
export const updateTenantCreditCards = (creditCards) => {
  return {
    type: UPDATE_TENANT_CREDIT_CARDS,
    creditCards,
  };
};

export const setDocuments = (document_list, prepaymentUUID) => {
  return {
    type: SET_DOCUMENTS,
    document_list,
    prepaymentUUID,
  };
};

export const updatePrepayment = (prepayment) => {
  return {
    type: UPDATE_PREPAYMENT,
    prepayment,
  };
};

const initialState = {
  loading: true,
  objects: {
    prepaymentInfo: [],
    personalInfo: {},
  },
};

export default function tenantInfoReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TENANT_INFO:
      return {
        ...state,
        loading: false,
        objects: {
          firstTime: action.tenantInfo?.first_time,
          prepaymentInfo: action.tenantInfo?.prepayment_data,
          personalInfo:
            action.tenantInfo?.personal_info ?? state.objects.personalInfo,
        },
      };
    case UPDATE_TENANT_ONBOARDING:
      return {
        ...state,
        loading: false,
        objects: {
          ...state.objects,
          prepaymentInfo: state.objects.prepaymentInfo.map((prepayment) => {
            if (prepayment.id === action.onboarding.prepayment_id) {
              prepayment.onboarding_data = action.onboarding;
              return prepayment;
            } else return prepayment;
          }),
        },
      };
    case UPDATE_TENANT_OFFBOARDING:
      return {
        ...state,
        loading: false,
        objects: {
          ...state.objects,
          prepaymentInfo: state.objects.prepaymentInfo.map((prepayment) => {
            if (prepayment.uuid === action.prepaymentUUID) {
              prepayment.offboarding_data = null;
              return prepayment;
            } else return prepayment;
          }),
        },
      };
    case UPDATE_TENANT_USER_INFO:
      return {
        ...state,
        loading: false,
        objects: {
          ...state.objects,
          personalInfo: action.personalInfo,
        },
      };
    case UPDATE_TENANT_DEPOSITS:
      return {
        ...state,
        loading: false,
        objects: {
          ...state.objects,
          prepaymentInfo: state.objects.prepaymentInfo.map((prepayment) => {
            const deposit_list = toArray(action.deposits);
            const first_deposit = first(deposit_list);
            if (prepayment.id === first_deposit.prepayment) {
              prepayment.deposits = action.deposits;
              return prepayment;
            } else return prepayment;
          }),
        },
      };
    case UPDATE_TENANT_INSURANCE:
      return {
        ...state,
        loading: false,
        objects: {
          ...state.objects,
          prepaymentInfo: state.objects.prepaymentInfo.map((prepayment) => {
            if (prepayment.uuid === action.enrollment.prepayment_uuid) {
              prepayment.renters_insurance_enrollment = action.enrollment;
              return prepayment;
            } else return prepayment;
          }),
        },
      };
    case UPDATE_TENANT_CREDIT_CARDS:
      return {
        ...state,
        loading: false,
        objects: {
          ...state.objects,
          personalInfo: {
            ...state.objects.personalInfo,
            ...action.creditCards,
          },
        },
      };
    case UPDATE_PREPAYMENT:
      return {
        ...state,
        loading: false,
        objects: {
          ...state.objects,
          prepaymentInfo: state.objects.prepaymentInfo.map((prepayment) => {
            if (prepayment.uuid === action.prepayment.uuid) {
              return action.prepayment;
            } else return prepayment;
          }),
        },
      };
    case SET_DOCUMENTS:
      return {
        ...state,
        loading: false,
        objects: {
          ...state.objects,
          prepaymentInfo: state.objects.prepaymentInfo.map((prepayment) => {
            if (prepayment.uuid === action.prepaymentUUID) {
              prepayment.document_list = action.document_list;
              return prepayment;
            } else return prepayment;
          }),
        },
      };
    case UPDATE_FEES:
      return {
        ...state,
        objects: {
          ...state.objects,
          prepaymentInfo: state.objects.prepaymentInfo.map((prepayment) => {
            const first_fee = first(action.fees);
            if (prepayment.id === first_fee.prepayment.uuid) {
              prepayment.fees = action.fees;
              return prepayment;
            } else return prepayment;
          }),
        },
      };
    default:
      return state;
  }
}
