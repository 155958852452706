import Div from "../../../../components/baseComponents/Div";
import Row from "../../../../components/baseComponents/Row";

import GeneralInfo from "./components/TenantGeneralInfo";
import PaymentInfoTools from "./components/TenantPaymentInfoTools";

import usePrepaymentFromParams from "../../../utils/usePrepaymentFromParams";
import prepaymentOverviewGeneralTabSections from "../../../../enums/prepaymentOverviewGeneralTabSections";
import NucleusLoader from "../../../components/NucleusLoader";
import prepaymentStatuses from "../../../../enums/prepaymentStatuses";
interface sectionProps {
  sectionType?: string;
}

const YardiPrepaymentTab = ({ sectionType }: sectionProps) => {
  const { loadingPrepayments, prepayment } = usePrepaymentFromParams();

  if (loadingPrepayments) {
    return (
      <Div>
        <NucleusLoader />
      </Div>
    );
  }

  return (
    <>
      <Row mt={{ default: 4 }} addSpace={true}>
        <Div width={{ default: 12 / 12 }}>
          <GeneralInfo
            generalInfo={{
              company_name: prepayment.general_info.company,
              yardi_tenant_id: prepayment.integration_info.tenant_id,
              company_code: prepayment.integration_info.company_code,
              building_id: prepayment.integration_info.building_id,
              unit_id: prepayment.integration_info.unit_id,
              yardi_db: prepayment.integration_info.yardi_db,
            }}
          />
        </Div>
      </Row>
      {prepayment.deposits_info.security_deposit &&
        [
          prepaymentStatuses.ACTIVE,
          prepaymentStatuses.DISBURSING,
          prepaymentStatuses.CLOSED,
        ].includes(prepayment.deposits_info.security_deposit.status_id) && (
          <Row mt={{ default: 4 }} addSpace={true}>
            <PaymentInfoTools
              paymentInfo={{
                amount: prepayment.payment_info.amount,
                funded_by: prepayment.payment_info.funded_by,
              }}
              prepaymentUUID={prepayment.general_info.prepayment_uuid}
              yardiTenantID={prepayment.integration_info.tenant_id}
              isOpen={
                sectionType ===
                prepaymentOverviewGeneralTabSections.PAYMENT_INFO
              }
            />
          </Row>
        )}
    </>
  );
};

export default YardiPrepaymentTab;
