import Container from "../../../../../components/baseComponents/Container";
import Row from "../../../../../components/baseComponents/Row";
import Div from "../../../../../components/baseComponents/Div";
import styled from "styled-components";
import { ComponentBreakpoints } from "../../../../../components/global/ModelInterfaces";

const StyledHeader = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
`;

interface Props {
  onClick?: () => void;
  mt?: ComponentBreakpoints;
  mb?: ComponentBreakpoints;
}

export const YardiStyledLogo = ({
  mt = { default: 5 },
  mb = { default: 5 },
}: Props) => (
  // Formatted Yardi logo element
  <StyledHeader justifyContent="center" mt={mt} mb={mb}>
    <Row justifyContent="center">
      <Div>
        <YardiLogo />
      </Div>
    </Row>
  </StyledHeader>
);
const YardiLogo = ({ onClick }: Props) => {
  return (
    <Container>
      <img
        src="https://rentable-public-assets.s3.us-east-2.amazonaws.com/yardi.png"
        onClick={onClick}
        alt="Yardi"
        style={{
          cursor: onClick ? "pointer" : "default",
          marginRight: "0.5em",
          verticalAlign: "middle",
          height: "1.6em",
          width: "8em  ",
        }}
      />
    </Container>
  );
};

export default YardiStyledLogo;
