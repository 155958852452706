import { useAppSelector } from "../store/hooks";
import { find } from "lodash";
import { RootState } from "..";
import {
  GenericObject,
  Prepayment,
} from "../components/global/ModelInterfaces";

const useGetCurrentPrepayment = (prepaymentUUID: string) => {
  const tenantInfoSlice: { loading: Boolean; objects: GenericObject } =
    useAppSelector((state: RootState) => state.tenantInfo);
  const loadingTenantInfo = tenantInfoSlice.loading;
  const prepaymentList = tenantInfoSlice.objects.prepaymentInfo;
  const firstTime: boolean = tenantInfoSlice.objects.firstTime;

  const prepayment = find(
    prepaymentList,
    (prepayment: Prepayment) => prepayment.uuid === prepaymentUUID
  );

  const onboarding = prepayment ? prepayment.onboarding_data : {};
  const offboarding = prepayment ? prepayment.offboarding_data : {};

  return { loadingTenantInfo, prepayment, firstTime, onboarding, offboarding };
};

export default useGetCurrentPrepayment;
