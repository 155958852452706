import Dropdown from "../../components/baseComponents/Dropdown";
import { Option } from "../../components/global/ModelInterfaces";

interface Building {
  uuid: string;
  name: string;
}

interface Props {
  value: Option | null;
  onChange: (value: Option | null) => void;
  buildings: Building[];
}

const BuildingSelect = ({ value, onChange, buildings }: Props) => {
  const propertyOptions: Option[] = buildings.map((building: Building) => ({
    id: building.uuid,
    label: building.name,
    identifier: building.uuid,
  }));

  return (
    <Dropdown
      name="Building"
      options={propertyOptions}
      value={value}
      onClick={onChange}
      placeholder="Select a building"
    />
  );
};

export default BuildingSelect;
