import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import ButtonToForm from "../../../../components/ButtonToForm";
import TenantLedgerSelect from "../components/TenantLedgerSelect";
import useNucleusPermissions from "../../../../../utils/useNucleusPermissions";
import TenantInviteForm from "../components/TenantInviteForm";
import { nucleusUserPermissions } from "../../../../../enums/nucleusPermissions";
import BeatLoader from "react-spinners/BeatLoader";
import YardiAPITool from "../components/YardiAPITool";
const ToolsSection = () => {
  const { loadingPermissions, integrationsAccess } = useNucleusPermissions();

  if (loadingPermissions) {
    return <BeatLoader />;
  }
  return (
    <DropdownCard title={"Yardi Tools"} initiallyOpen={false}>
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
          <ButtonToForm formTitle="View Tenant Ledger" buttonText="Get Ledger">
            <TenantLedgerSelect />
          </ButtonToForm>
        </Div>
        {!(nucleusUserPermissions.NONE == integrationsAccess) && (
          <Div width={{ lg: 6 / 12 }}>
            <ButtonToForm
              formTitle="Manual Tenant Invite"
              buttonText="Invite Individual Tenant"
            >
              <TenantInviteForm />
            </ButtonToForm>
          </Div>
        )}
      </Row>
      <Row>
        <Div width={{ default: 12 / 12 }}>
          <ButtonToForm formTitle="Yardi API Tool" buttonText="Yardi API Tool">
            <YardiAPITool />
          </ButtonToForm>
        </Div>
      </Row>
    </DropdownCard>
  );
};

export default ToolsSection;
