export interface Prepayment {
  building_name?: String;
  building_uuid: String;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  street_address: string;
  zip_code: string;
  status: string;
  status_id: number;
  is_disbursable: boolean;
  start_date: string;
  end_date: string;
  amount: string;
  tenant_name: string;
  tenant_email: string;
  last_months_rent_amount: string;
  security_deposit_amount: string;
  dwolla_fee: number;
  w9_signed: boolean;
  tenant_id: number;
  has_disbursable_rent: boolean;
  assigned_to: string;
  created_at: string;
  id: number;
  uuid: string;
  unit_uuid: string;
  deposits: GenericObject;
  fees: Fee[];
  document_list: Document[];
  insurance_enrollment: GenericObject;
  insurance_policy_url: string;
}
export interface Deposit {
  id: number;
  prepayment: number;
  uuid: string;
  amount: number;
  status: string;
  status_id: number;
  type: number;
  interest_eligible: boolean;
  is_paid_monthly: boolean;
  additional_funds_requests: AdditionalFundsRequest[];
  remaining_balance: number;
  latest_interest_disbursal_amount: number;
  latest_interest_disbursal_date: string;
  total_accrued_interest: number;
  dwolla_fee: number;
  deleted_at: string;
  restored_at: string;
}

export interface DepositList {
  type: number;
  amount: number;
  id: number;
}

export interface Deduction {
  id?: number;
  uuid: string;
  amount: number | null;
  category: number | null;
  documentation?: any | null;
  description?: string | null;
}

export interface Building {
  address_1: string;
  city: string;
  id: number;
  uuid: string;
  state: string;
  street_address: string;
  zip_code: string;
  name: string;
  is_inactive: boolean;
  bank_account_uuid?: string;
}

export interface Unit {
  name: string;
  street_address: string;
  address_2?: string;
  city: string;
  state: string;
  zip_code: string;
  uuid: string;
  building_id: number;
  building_uuid: string;
  is_inactive: boolean;
  metrics?: Array<Array<any>>;
}

export interface Metric {
  name: string;
  value: string;
  editable: boolean;
}

export const newMetric = (name: string, value: string, editable?: boolean) => {
  const metric: Metric = {
    name: name,
    value: value,
    editable: editable ?? false,
  };
  return metric;
};

export interface RentableUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_landlord: boolean;
  is_tenant: boolean;
  is_active: boolean;
}

export interface NucleusUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  nucleus_key: string;
}
export interface Landlord {
  id: number;
  full_name: string;
  email: string;
}

export interface Tenant {
  uuid: string;
  name: string;
  email: string;
  depositList: DepositList[];
  feeList: Fee[];
}

export interface BankAccount {
  mask: string;
  name: string;
  bank_name: string;
  uuid: string;
  pending_confirmation: boolean;
  plaid_link: string | null;
  manually_verified: boolean;
  payment_personal_verified: boolean;
  payment_business_verified: boolean;
  owner: number;
}

export interface Invite {
  uuid: string;
  invited_at: string;
  accepted: boolean;
  street_address: string;
  tenant_name: string;
  lease_start_date: string;
  prepayment_uuid: string;
  last_reminder_sent: string;
}

export interface Document {
  name: string;
  url: string;
  type: number;
  uuid: string;
  deposit_id: number;
}

export interface MenuItem {
  label: string;
  function: Function;
}

export interface GenericObject {
  [key: string]: any;
}

export interface Option {
  label: string;
  identifier?: string;
  id: string;
}

export interface InterestTransactions {
  total_accrued_interest?: number;
  latest_interest_disbursal_amount?: number;
  latest_interest_disbursal_date?: string;
}

export interface AdditionalFundsRequest {
  uuid: string;
  amount: number;
  requested_at: string;
  last_reminder_sent_at: string;
  number_of_reminders_sent: number;
  funded_at: string;
  fee: number;
  processor_fee: number;
  status: string;
  status_id: number;
}

export interface Renewal {
  requested_at: string;
  new_end_date: string;
  previous_end_date: string;
  renewal_amount: string;
  renewal_funded_at: string;
}

export interface CreateDepositRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  startDate: string;
  endDate: string;
  unitUUID: string;
  securityDeposit: number;
  lastMonthsRent: number;
  securityDepositInterest: number;
  lastMonthsRentInterest: number;
  userPaid: boolean;
}

export interface LandlordBuilding {
  id: number;
  address_1: string;
  city: string;
  uuid: string;
  state: string;
  street_address: string;
  zip_code: string;
  name: string;
  bank_account_uuid: string;
  landlords: Landlord[];
}

export interface Fee {
  id: number;
  prepayment_uuid: string;
  uuid: string;
  status: number;
  amount: number;
  description: string;
  type: string;
  type_id: number;
  platform_charge: number;
}

export interface PendingFee {
  uuid: string;
  amount: number;
  description: string;
  type: string;
  type_id: number;
}

export interface TenantOnboardingObject {
  id: number;
  uuid: string;
  introduction_completed_at: string | null;
  personal_info_completed_at: string | null;
  documentation_completed_at: string | null;
  bank_link_completed_at: string | null;
  renters_insurance_completed_at: string | null;
  payment_completed_at: string | null;
  user: number;
  prepayment_uuid: string;
}

export interface TenantUserInfo {
  id: number;
  date_of_birth: string;
  mailing_address_1: string;
  mailing_address_2: string;
  city: string;
  state: string;
  zip_code: string;
  no_ssn: boolean;
  phone_number: string;
  user: number;
}
export interface TenantPrepaymentInfo {
  id: number;
  created_at: string;
  address_1: string;
  address_2: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  status: string;
  status_id: number;
  start_date: string;
  end_date: string;
  prepayment_uuid: string;
  unit_uuid: string;
  amount: number;
  tenant_name: string;
  assigned_to: string;
  document_list: Document[];
  deposits: {
    security_deposit?: Deposit;
    last_months_rent?: Deposit;
  };
  tenant_can_pay_monthly: boolean;
  fees: Fee[];
  company: Company;
}

export interface PrepaymentFilters {
  active: boolean;
  pending: boolean;
  processing: boolean;
  closed: boolean;
  disbursing: boolean;
}

export interface Company {
  name: string;
  id: number;
  uuid: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  is_inactive: string;
  can_charge_fees: boolean;
}

export interface TenantFinancialInfo {
  sufficientFundsFullPayment: boolean;
  sufficientFundsMonthlyPayment: boolean;
  amountToPay: number;
  paymentPlanApproval: GenericObject;
  hasSavedCreditCard: boolean;
}

export interface ComponentBreakpoints {
  default?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
}

export interface DepositTransaction {
  id: number;
  uuid: string;
  transaction_date: string;
  amount: string;
  fee_amount: number;
  company_pays_fee: boolean;
  user_email: string;
  dwolla_transaction_id: string | undefined;
}

export interface DwollaTransaction {
  id: number;
  uuid: string;
  transaction_date: string;
  amount: number;
  fee_transaction: number | undefined;
}

export interface DeferredPaymentTransaction {
  uuid: string;
  transaction_date: string;
  amount: number;
  fee: number;
  transfer_id: string;
  is_manual_payment: boolean;
}

export interface DelayedDisbursement {
  uuid: string;
  prepayment: string;
  returned_deposit_amount: number;
  security_deposit_interest: number;
  last_months_rent_interest: number;
  deposit_amount_released: boolean;
  deposit_interest_released: boolean;
  rent_interest_released: boolean;
  disbursed_at: string | undefined;
  total_amount_to_disburse: number;
  check_sending_fee: number;
}

export interface LobMailing {
  uuid: string;
  type: string;
  lob_id: string;
  check_is_cashed: boolean;
  fee: number | undefined;
}

export interface RenewalPlanApprovalInfo {
  sufficient_funds_for_fee: boolean;
  sufficient_funds_for_monthly_payments: boolean;
  enrollment_fee: number;
  payout_amount: number;
  approved_number_months: number;
  monthly_payment_amount: number;
  total_payback_amount: number;
}

export interface NucleusUser {
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  nucleus_key: string;
  loading: boolean;
  permissions: NucleusUserPermissions;
}
export interface NucleusPrepayment {
  general_info: NucleusPrepaymentGeneralInfo;
  deposits_info: {
    security_deposit: Deposit | undefined;
    last_months_rent: Deposit | undefined;
  };
  invite_info: NucleusInviteInfo | undefined;
  payment_info: NucleusPaymentInfo | undefined;
  payment_plan_info: NucleusPaymentPlanInfo | undefined;
  bank_info: BankAccount | undefined;
  deductions_info: {
    security_deposit: NucleusDeductionInfo | undefined;
    last_months_rent: NucleusDeductionInfo | undefined;
  };
  disbursement_info: NucleusDisbursementInfo | undefined;
  additional_funds_info: AdditionalFundsRequest[];
  partial_refund_info: DepositTransaction[];
}

export interface NucleusPrepaymentGeneralInfo {
  address: string;
  company: string;
  date_of_birth: string;
  email: string;
  invite_accepted: string;
  lease_end_date: string;
  lease_start_date: string;
  name: string;
  phone_number: string;
  prepayment_uuid: string;
}

export interface NucleusInviteInfo {
  uuid: string;
  prepayment_uuid: string;
  invited_at: string;
  accepted_at: string;
  last_reminder_sent: string;
  reminders_sent_count: number;
  street_address: string;
  tenant_name: string;
  lease_start_date: string;
  email: string;
}

export interface NucleusPaymentInfo {
  method: string;
  date: string;
  amount: number;
  fee: number;
  funded_by: string;
  stripe_info:
    | undefined
    | {
        payment_intent: string;
      };
  dwolla_info:
    | undefined
    | {
        correlation_id: string;
        bank_account: BankAccount | undefined;
      };
}

export interface NucleusPaymentPlanInfo {
  number_of_payments: number;
  initial_payment: number;
  monthly_payment: number;
  fee: number;
  last_scheduled_payment: string;
  uuid: string;
}

export interface NucleusDeductionInfo {
  type: string;
  status: string;
  status_id: number;
  deduction_transaction: DepositTransaction | undefined;
  dwolla_transaction: DwollaTransaction | undefined;
  bank_account: BankAccount | undefined;
}

export interface NucleusDisbursementInfo {
  status: string;
  disbursement_date: string;
  amount: number;
  dwolla_transaction: string | undefined;
  stripe_transaction: string | undefined;
  lob_check: LobMailing | undefined;
  bank_account: BankAccount | undefined;
  withheld_amount: number;
  deductions: Deduction[];
  delayed_disbursement: DelayedDisbursement | undefined;
}

export interface NucleusUserPermissions {
  prepayment_overview: number;
  company_overview: number;
  admin_settings: number;
  integrations_access: number;
  engineering_access: number;
  user?: string;
}
export interface NucleusCompany extends Company {
  integration_name: string;
  integration_type: number;
  company_settings: CompanySettings;
  bank_info: BankAccount[];
  landlords: Landlord[];
}

export interface CompanySettings {
  company: Company;
  pays_processor_fee: boolean;
  tenant_can_pay_monthly: boolean;
  pays_tenant_fee: boolean;
  can_charge_fees: boolean;
  requires_renters_insurance: boolean;
  renters_insurance_interested_party_required: boolean;
  max_payment_plan_months: number;
  auto_link_landlords_to_buildings: boolean;
  automatically_release_rent: boolean;
  send_certified_mail: boolean;
  transaction_fee_minimum: number;
  transaction_fee_limit: number;
  self_fund_fee: number;
  hide_renters_insurance: boolean;
  check_sending_fee: number;
}

export interface CreditCard {
  uuid: string;
  last_four: string;
  user: string;
  country: string;
  payment_processor_id: string;
  payment_processor_customer_id: string;
}
