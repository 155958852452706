import styled from "styled-components";
import { Redirect, useParams } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { GenericObject } from "../../global/ModelInterfaces";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import PageLoader from "../../PageLoader";
import { monthDayYearFormatter } from "../../global/utils";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";
interface Props {
  nextStep: () => void;
}

const StyledIntroduction = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
`;

const StyledHeaderText = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.navy};
`;

const StyledRentableText = styled.span`
  font-weight: ${(props) => props.theme.font_weight.bolder};
  color: ${(props) => props.theme.colors.primary};
`;

const StyledIntroductionText = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
`;

const StyledIntroductionButton = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  border: ${(props) => props.theme.borders.primarySM};
  border-radius: ${(props) => props.theme.border_radius.XS};
  box-shadow: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
  }
`;

const IntroductionStep = ({ nextStep }: Props) => {
  const currentUser = useAppSelector((state) => state.auth.user);

  const { prepaymentUUID } = useParams<GenericObject>();
  const { loadingTenantInfo, prepayment, firstTime } =
    useGetCurrentPrepayment(prepaymentUUID);

  if (!prepayment) {
    return <Redirect to="/dashboard" />;
  } else if (loadingTenantInfo) {
    return (
      <Div width={{ default: 1 }} justifyContent="center">
        <PageLoader />
      </Div>
    );
  }

  return (
    <Div width={{ default: 1 }} alignItems="center">
      <StyledIntroduction justifyContent="center" pt={{ default: 3 }}>
        <StyledHeaderText>
          {currentUser.first_name}, welcome {!firstTime && "back"} to{" "}
          <StyledRentableText> Rentable!</StyledRentableText>
        </StyledHeaderText>
      </StyledIntroduction>
      <StyledIntroductionText
        textAlign="center"
        pt={{ default: 3 }}
        pb={{ default: 3 }}
        width={{ sm: 1, md: 1, lg: 10 / 12 }}
      >
        <Row justifyContent="center">
          <span>
            You've been invited by
            <StyledHeaderText>
              {` ${prepayment.company.name} `}
            </StyledHeaderText>
          </span>
        </Row>
        <Row mt={{ default: 3 }} justifyContent="center">
          <span>Here are your lease details:</span>
        </Row>
        <Row justifyContent="center">
          <span>Address:</span>
          <StyledHeaderText>{prepayment.street_address}</StyledHeaderText>
        </Row>
        <Row justifyContent="center">
          <span>Start Date:</span>
          <StyledHeaderText>
            {monthDayYearFormatter(prepayment.start_date)}
          </StyledHeaderText>
        </Row>
        <Row justifyContent="center">
          <span>End Date:</span>
          <StyledHeaderText>
            {prepayment.end_date ? (
              <>{monthDayYearFormatter(prepayment.end_date)}</>
            ) : (
              "Month to Month"
            )}
          </StyledHeaderText>
        </Row>
      </StyledIntroductionText>
      <StyledIntroductionButton
        textAlign="center"
        onClick={nextStep}
        pt={{ default: 3 }}
        pb={{ default: 3 }}
        mt={{ default: 3 }}
        width={{ sm: 1, md: 3 / 4, lg: 1 / 2 }}
      >
        {firstTime ? "Let's get started!" : "Let's keep going!"}
      </StyledIntroductionButton>
    </Div>
  );
};

export default IntroductionStep;
