import styled from "styled-components";
import Div from "../../../components/baseComponents/Div";
import Row from "../../../components/baseComponents/Row";
import DropdownCard from "../../../components/baseComponents/DropdownCard";
import { NucleusCompany } from "../../../components/global/ModelInterfaces";

interface Props {
  generalInfo: NucleusCompany;
}

const StyledLabel = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const GeneralInfo = ({ generalInfo }: Props) => {
  return (
    <DropdownCard title={"General Info"}>
      <Div width={{ default: 12 / 12 }}>
        <Row>
          <StyledLabel>Company Address:</StyledLabel>
          {`${generalInfo.street_address}, ${generalInfo.city}, ${generalInfo.state} ${generalInfo.zip_code}`}
        </Row>
        <Row>
          <StyledLabel>Company UUID:</StyledLabel>
          {generalInfo.uuid}
        </Row>
        <Row>
          <StyledLabel>Integration:</StyledLabel>
          {generalInfo.integration_name}
        </Row>
        <Row>
          <StyledLabel>Bank accounts:</StyledLabel>
          {generalInfo.bank_info && generalInfo.bank_info.length > 0 ? (
            generalInfo.bank_info.map((bank) => (
              <Div key={bank.bank_name}>
                ***{bank.mask} ({bank.bank_name})
              </Div>
            ))
          ) : (
            <Div>No linked bank accounts available</Div>
          )}
        </Row>
      </Div>
    </DropdownCard>
  );
};

export default GeneralInfo;
