import styled from "styled-components";

import Div from "../../../../../components/baseComponents/Div";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import Row from "../../../../../components/baseComponents/Row";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import Button from "../../../../../components/baseComponents/Button";
import TenantLedgerModal from "./TenantLedgerModal";
import { useState } from "react";
import { retrieveTenantLedger } from "../../../../utils/yardiUtils";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

interface GeneralInfoProps {
  generalInfo: {
    company_name: string;
    yardi_tenant_id: string;
    company_code: string;
    building_id?: { building_name: string; building_code: string };
    unit_id: string;
    yardi_db: string;
  };
}

const GeneralInfo = ({ generalInfo }: GeneralInfoProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [ledgerData, setLedgerData] = useState({});

  const handleSubmit = () => {
    const successCallBack = (ledgerData: GenericObject) => {
      setLoading(false);
      setErrorMessage("");
      setLedgerData(ledgerData);
      setModalOpen(true);
    };
    const failureCallback = (err: string) => {
      setModalOpen(false);
      setLedgerData({});
      setErrorMessage(`Error retrieving tenant ledger: ${err}`);
      setLoading(false);
    };
    setLoading(true);
    setModalOpen(false);
    setErrorMessage("");
    setLedgerData({});
    retrieveTenantLedger(
      generalInfo.yardi_tenant_id,
      generalInfo.yardi_db,
      generalInfo.company_code,
      successCallBack,
      failureCallback
    );
  };

  return (
    <DropdownCard title={"Yardi Tenant Information"}>
      <Div width={{ default: 12 / 12 }}>
        <Row>
          <StyledLabel>Yardi Tenant ID:</StyledLabel>
          {generalInfo.yardi_tenant_id}
        </Row>
        <Row>
          <StyledLabel>Yardi Company Name:</StyledLabel>
          {generalInfo.company_name}
        </Row>
        <Row>
          <StyledLabel>Yardi Company Code:</StyledLabel>
          {generalInfo.company_code}
        </Row>
        <Row>
          <StyledLabel>Yardi Database:</StyledLabel>
          {generalInfo.yardi_db}
        </Row>
        <Row>
          <StyledLabel>Yardi Building Name:</StyledLabel>
          {generalInfo.building_id?.building_name}
        </Row>
        <Row>
          <StyledLabel>Yardi Building Code:</StyledLabel>
          {generalInfo.building_id?.building_code}
        </Row>
        <Row>
          <StyledLabel>Yardi Unit ID:</StyledLabel>
          {generalInfo.unit_id}
        </Row>
        {errorMessage && (
          <Row justifyContent="center" mb={{ default: 3 }}>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </Row>
        )}
        <Row>
          <Div width={{ default: 4 / 12 }} mt={{ default: 3 }}>
            <Button
              loading={loading}
              text="Retrieve Tenant Ledger"
              onClick={handleSubmit}
            />
          </Div>
        </Row>
        <TenantLedgerModal
          ledgerData={ledgerData}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      </Div>
    </DropdownCard>
  );
};

export default GeneralInfo;
