import { useState } from "react";
import styled from "styled-components";

import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import { currencyFormatter } from "../../../../../components/global/utils";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import CollapsibleJson from "../../../../../components/baseComponents/CollapsibleJson";

import { postDepositReceipt } from "../../../../utils/yardiUtils";
import SuccessMessage from "../../../../../components/baseComponents/SuccessMessage";
import Input from "../../../../../components/baseComponents/Input";
import ConfirmationModal from "../../../../../components/baseComponents/ConfirmationModal";
import WarningMessage from "../../../../../components/baseComponents/WarningMessage";
import { set } from "lodash";

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledDangerHeader = styled(ErrorMessage)`
  font-size: ${(props) => props.theme.font_size.headlineXL};
`;

const ModalDiv = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

interface PaymentInfoToolsProps {
  yardiTenantID: string;
  prepaymentUUID: string;
  paymentInfo: {
    amount: number;
    funded_by: string;
  };
  isOpen: boolean;
}

const parseReceiptSuccess = (json: GenericObject) => {
  /**
   * Navigate through the JSON to find the "#text" field, that contains the receipt id.
   * Also, check if the message type is "Error" and consider it as an error.
   * Return the message if it is a success message, otherwise return null.
   **/
  try {
    const parsedMessage =
      json?.message?.["soap:Envelope"]?.["soap:Body"]
        ?.ImportReceipt_LoginResponse?.ImportReceipt_LoginResult?.Messages
        ?.Message?.["#text"];
    const messageType =
      json?.message?.["soap:Envelope"]?.["soap:Body"]
        ?.ImportReceipt_LoginResponse?.ImportReceipt_LoginResult?.Messages
        ?.Message?.["@messageType"];
    if (messageType === "Error") return null;

    return typeof parsedMessage === "string"
      ? messageType + ": " + parsedMessage
      : null;
  } catch (error) {
    return null;
  }
};

const PaymentInfoTools = ({
  paymentInfo,
  prepaymentUUID,
  yardiTenantID,
  isOpen,
}: PaymentInfoToolsProps) => {
  /**
   * Allows the user to post a deposit receipt to Yardi.
   */
  const [postingReceipt, setPostingReceipt] = useState(false);
  const [receiptAmount, setReceiptAmount] = useState<string>(
    paymentInfo.amount.toString()
  );
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [override, setOverride] = useState<boolean>(false);
  const [overrideError, setOverrideError] = useState<string>("");
  const [tryOverride, setTryOverride] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorData, setErrorData] = useState<GenericObject | null>(null);
  const [postingReceiptResult, setPostingReceiptResult] =
    useState<GenericObject | null>(null);

  const postReceiptSuccess = (response: GenericObject) => {
    const postResult = parseReceiptSuccess(response); // Parse the response to get the receipt id
    if (postResult) {
      setSuccessMessage(postResult);
      setPostingReceiptResult(response.message);
    } else {
      setErrorMessage(response.toString());
      setErrorData({ error: response });
    } // Well-formatted response, but not the expected one
    setPostingReceipt(false);
    setOverride(false);
    setConfirmationOpen(false);
  };

  const postReceiptError = (response: GenericObject) => {
    const defaultError = "An error occurred while posting the receipt.";
    setTryOverride(true);
    if (response.data) {
      setErrorMessage(response.data?.message || defaultError); // Error message from the API
      setErrorData(response.data);
    } else {
      setErrorMessage(defaultError); // Default error message
      setErrorData({ error: response });
    }
    setPostingReceipt(false);
    setOverride(false);
  };

  const sendReceiptToYardi = () => {
    /* Request API the receipt to be posted */
    setConfirmationOpen(false);
    setTryOverride(false);
    setPostingReceipt(true);
    setErrorMessage(null);
    setSuccessMessage(null);
    setErrorData(null);
    setPostingReceiptResult(null);
    postDepositReceipt(
      prepaymentUUID,
      receiptAmount,
      override, // if 'override' is typed in the input field
      postReceiptSuccess,
      postReceiptError
    );
  };

  const checkOverride = (value: string) => {
    // Check if the user typed 'override' in the input field without typos
    if (value === "override") {
      setOverrideError("");
      setOverride(true);
    } else if (value === "") {
      setOverrideError("");
      setOverride(false);
    } else {
      setOverrideError(
        "Typo in the input field. Please type 'override' or leave it empty."
      );
      setOverride(false);
    }
  };

  return (
    <DropdownCard title={"Yardi Tenant Payment Tools"} initiallyOpen={isOpen}>
      <Div width={{ default: 12 / 12 }}>
        <Row>
          <StyledLabel>Yardi Tenant ID:</StyledLabel>
          {yardiTenantID}
        </Row>
        <Row>
          <StyledLabel>Amount:</StyledLabel>
          {currencyFormatter.format(paymentInfo.amount)}
        </Row>
        <Row>
          <StyledLabel>Funded By:</StyledLabel>
          {paymentInfo.funded_by}
        </Row>
        <Row>
          <StyledLabel>Prepayment UUID:</StyledLabel>
          {prepaymentUUID}
        </Row>

        <Row mt={{ default: 4 }}>
          <Div width={{ default: 3 / 12 }}>
            <Input
              value={receiptAmount}
              placeholderText={receiptAmount}
              onChange={(e) => setReceiptAmount(e.target.value)}
              label="Post the receipt amount"
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ default: 3 / 12 }} mt={{ default: 1 }}>
            <Button
              text={
                (tryOverride ? "Force " : "") + "Post Deposit Receipt to Yardi"
              }
              onClick={() => setConfirmationOpen(true)}
              type={tryOverride ? "warning" : "primary"}
              loading={postingReceipt}
            />
          </Div>
        </Row>
        <Row mt={{ default: 4 }}>
          <Div width={{ default: 12 / 12 }}>
            {successMessage && (
              <>
                <SuccessMessage>{successMessage}</SuccessMessage>
                <CollapsibleJson
                  title="Receipt Posting Result"
                  json={postingReceiptResult || {}}
                  type="success"
                  isOpen={false}
                  formatted={true}
                  collapsible={false}
                />
              </>
            )}
            {errorMessage && (
              <>
                {" "}
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <Row mt={{ default: 4 }}>
                  <CollapsibleJson
                    title="Details"
                    type="success"
                    json={errorData || {}}
                    isOpen={false}
                    formatted={true}
                    collapsible={false}
                  />
                </Row>
              </>
            )}
          </Div>
        </Row>
      </Div>
      <Div width={{ default: 2 / 12 }} justifyContent="center">
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          onClose={() => {
            setTryOverride(false);
            setOverride(false);
            setOverrideError("");
            setConfirmationOpen(false);
          }}
          errorMessage={overrideError}
          onConfirm={sendReceiptToYardi}
          buttonType="danger"
          message=""
          sizeVariant="small"
        >
          <Row justifyContent="center">
            <StyledDangerHeader>! DANGER !</StyledDangerHeader>
          </Row>
          <Row mt={{ default: 4 }} justifyContent="center">
            <ModalDiv width={{ default: 10 / 12 }} alignItems="center">
              You are about to post the receipt to the Yardi ledger.
            </ModalDiv>
            <ModalDiv
              width={{ default: 10 / 12 }}
              mt={{ default: 4 }}
              alignItems="center"
            >{`Tenant ID = ${yardiTenantID}`}</ModalDiv>
            <ModalDiv
              width={{ default: 10 / 12 }}
              mt={{ default: 3 }}
              alignItems="center"
            >{`Amount = ${receiptAmount}`}</ModalDiv>
            <ModalDiv
              width={{ default: 10 / 12 }}
              mt={{ default: 4 }}
              alignItems="center"
            >
              Please double check before proceeding. This action cannot be
              undone!
            </ModalDiv>
          </Row>
          {tryOverride && (
            <>
              <Row mt={{ default: 4 }} justifyContent="center">
                <WarningMessage>
                  Type 'override' if you are sure you want to post the receipt
                  to Yardi, even if it has already been posted.
                </WarningMessage>
              </Row>
              <Row justifyContent="center" mt={{ default: 4 }}>
                <Input
                  heightVariant="small"
                  type="text"
                  error={!!overrideError}
                  onChange={(e) => checkOverride(e.target.value)}
                ></Input>
              </Row>
            </>
          )}
        </ConfirmationModal>
      </Div>
    </DropdownCard>
  );
};

export default PaymentInfoTools;
