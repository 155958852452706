import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import Div from "./Div";
import EyeClose from "../../images/EyeClose";
import EyeOpen from "../../images/EyeOpen";
interface PrefixProps {
  focused: boolean;
}
const StyledIcon = styled(Div)<PrefixProps>`
  width: 3.5rem;
  background-color: lightgray;
  display: inline;
  height: 3.5rem;
  border-radius: 0 8px 8px 0;
  margin: 8px 0;
  padding: 0.75rem 0;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  ${(props) =>
    variant({
      prop: "focused",
      variants: {
        true: {
          paddingTop: "0.65rem",
          paddingRight: "0.1rem",
          border: "2px solid " + props.theme.colors.outlineGrey,
          borderLeft: 0,
        },
      },
    })}
`;

const StyledInputContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

interface StyleProps {
  variant?: string;
  error?: boolean;
  prefixed?: boolean;
  heightVariant?: string;
  focused?: boolean;
}

const StyledInput = styled.input<StyleProps>`
  border-radius: 8px 0 0 8px;
  margin: 8px 0px;
  border: none;
  ${(props) =>
    variant({
      prop: "focused",
      variants: {
        true: {
          borderRadius: "8px 0 0 8px",
          width: "100%",
          outline: props.focused ? "none !important" : "inherit",
          border: props.focused
            ? "2px solid " + props.theme.colors.outlineGrey
            : "inherit",
          borderRight: props.focused ? "none" : "inherit",
        },
      },
    })};
  padding: 1rem;
  display: inline;
  width: 100%
    ${(props) =>
      variant({
        prop: "variant",
        variants: {
          light: {
            backgroundColor: props.theme.colors.grey5,
            color: props.theme.colors.grey60,
            "::placeholder": {
              color: props.theme.colors.grey60,
            },
          },
          dark: {
            backgroundColor: props.theme.colors.grey80,
            color: props.theme.colors.grey20,
            "::placeholder": {
              color: props.theme.colors.grey20,
            },
          },
        },
      })};
  ${(props) =>
    variant({
      prop: "error",
      variants: {
        true: {
          backgroundColor: props.theme.colors.backgroundWarning,
        },
      },
    })};
  ${variant({
    prop: "heightVariant",
    variants: {
      default: {
        height: "3.5rem",
      },
      short: {
        height: "2.5rem",
      },
    },
  })};
`;

const StyledLabel = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
`;

const StyledHelpText = styled.span`
  font-size: 0.563rem;
  color: ${(props) => props.theme.colors.grey60};
`;

interface Props {
  defaultValue?: string;
  backgroundColor?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  error?: boolean;
  heightVariant?: string;
  showLabel?: boolean;
}

const PasswordInput = ({
  defaultValue,
  backgroundColor,
  onChange,
  value,
  showLabel = true,
  error = false,
  heightVariant = "default",
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleInputScroll = (event: any) => {
      event.target.blur();
    };

    // Bind the event listener
    document.addEventListener("wheel", handleInputScroll);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("wheel", handleInputScroll);
    };
  }, []);
  useEffect(() => {
    // Moving cursor to the end
    if(!inputRef.current) return;

    inputRef.current.selectionStart = inputRef.current.value.length;
    inputRef.current.selectionEnd = inputRef.current.value.length;
  }, [showPassword]);
  return (
    <div>
      {showLabel && <StyledLabel>Password</StyledLabel>}
      <StyledInputContainer>
        <Div width={"fill"}>
          <StyledInput
            ref={inputRef}
            variant={backgroundColor || "light"}
            defaultValue={defaultValue}
            onChange={onChange}
            value={value}
            error={error}
            type={showPassword ? "text" : "password"}
            focused={focused}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            heightVariant={heightVariant}
            placeholder={showLabel ? "" : "Password"}
          />
        </Div>
        <StyledIcon
          width={{ default: 2 / 12 }}
          onClick={() => {
            setShowPassword((previousShowPassword) => !previousShowPassword);
            setFocused(true);
            if(inputRef.current){
              inputRef.current.focus();
            }
          }}
          focused={focused}
        >
          {showPassword ? (
            <EyeOpen height="18px" width="18px" />
          ) : (
            <EyeClose height="18px" width="18px" />
          )}
        </StyledIcon>
      </StyledInputContainer>
    </div>
  );
};

export default PasswordInput;
