// React imports
import { useState } from "react";
import styled from "styled-components";

// Component imports
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import TextArea from "../../../../../components/baseComponents/TextArea";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import BuildingSelect from "../../../BuildingSelect";
import CopyButton from "../../../../../components/baseComponents/CopyButton";
import ResourceTypeSelect from "./ResourceTypeSelect";
import RequestTypeSelect from "../../../../../components/baseComponents/RequestTypeSelect";

// Utility and type imports
import { createCustomAPIRequest } from "../../../../utils/yardiUtils";
import {
  GenericObject,
  Option,
} from "../../../../../components/global/ModelInterfaces";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";

const TextAreaContainer = styled(Div)`
  position: relative;
  min-height: 400px;
`;

const defaultResourceType: Option = {
  id: "resident-data",
  label: "ItfResidentData",
  identifier: "ItfResidentData",
};

const YardiAPITool = () => {
  const {
    company,
    errorLoading,
    errorMessage: companyErrorMessage,
  } = useCompanyFromParams();
  const [selectedBuilding, setSelectedBuilding] = useState<Option | null>(null);
  const [resourceType, setResourceType] = useState<Option | null>(
    defaultResourceType
  );
  const [requestType, setRequestType] = useState("");
  const [requestPayload, setRequestPayload] = useState("{}");
  const [jsonError, setJsonError] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [results, setResults] = useState<GenericObject | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  const handlePayloadChange = (value: string) => {
    setRequestPayload(value);
    try {
      JSON.parse(value);
      setJsonError(null);
    } catch (e) {
      setJsonError("Invalid JSON format");
    }
  };

  const getPayloadObject = (): Record<string, any> => {
    try {
      return JSON.parse(requestPayload);
    } catch (e) {
      return {};
    }
  };

  const handleSubmit = () => {
    if (!selectedBuilding?.identifier) {
      setErrorMessage("Please select a building");
      return;
    }
    if (!resourceType?.identifier || !requestType) {
      setErrorMessage("Please select a resource type and request type");
      return;
    }
    setLoading(true);
    const successCallback = (res: GenericObject) => {
      setResults(res);
      setErrorMessage("");
      setLoading(false);
    };
    const failureCallback = (err: string) => {
      setErrorMessage(`Error creating custom API request: ${err}`);
      setLoading(false);
    };
    createCustomAPIRequest(
      selectedBuilding.identifier,
      resourceType.identifier,
      requestType,
      getPayloadObject(),
      successCallback,
      failureCallback
    );
  };

  const handleCopyResults = async () => {
    if (!results) return;

    try {
      const formattedJson = JSON.stringify(results, null, 2);
      await navigator.clipboard.writeText(formattedJson);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    } catch (err) {
      setErrorMessage("Failed to copy text: " + err);
    }
  };

  return (
    <Div>
      {errorLoading && companyErrorMessage && (
        <Row>
          <Div width={{ default: 12 / 12 }}>
            <ErrorMessage>
              Error Loading Company: {companyErrorMessage}
            </ErrorMessage>
          </Div>
        </Row>
      )}
      {errorMessage && (
        <Row>
          <Div width={{ default: 12 / 12 }}>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </Div>
        </Row>
      )}
      <Row>
        <Div width={{ default: 12 / 12 }}>
          <BuildingSelect
            value={selectedBuilding}
            onChange={setSelectedBuilding}
            buildings={company.buildings}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 6 / 12 }}>
          <ResourceTypeSelect value={resourceType} onChange={setResourceType} />
        </Div>
        <Div width={{ default: 6 / 12 }}>
          <RequestTypeSelect
            resourceType={resourceType?.identifier || ""}
            onSelectRequestType={setRequestType}
            defaultValue={requestType}
          />
        </Div>
      </Row>
      <Row>
        <Div width={{ default: 12 / 12 }}>
          <TextArea
            label="Request Payload (JSON)"
            value={requestPayload}
            onChange={(e) => handlePayloadChange(e.target.value)}
            error={!!jsonError}
            helpText={jsonError || "Must be valid JSON"}
            placeholderText="{\n  // Your JSON here\n}"
            heightVariant="short"
          />
        </Div>
      </Row>
      <Row justifyContent="center">
        <Div width={{ default: 6 / 12 }}>
          <Button onClick={handleSubmit} loading={loading}>
            Create Custom API Request
          </Button>
        </Div>
      </Row>
      {results && (
        <Row>
          <Div width={{ default: 12 / 12 }}>
            <TextAreaContainer mt={{ default: 0.5 }}>
              <TextArea
                label="Results"
                value={JSON.stringify(results, null, 2)}
                readOnly
                heightVariant="tall"
              />
              <CopyButton
                onClick={handleCopyResults}
                copySuccess={copySuccess}
              />
            </TextAreaContainer>
          </Div>
        </Row>
      )}
    </Div>
  );
};

export default YardiAPITool;
