import { useState } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import Div from "./Div";
import Row from "./Row";
import { GenericObject } from "../global/ModelInterfaces";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import Container from "./Container";
import ToggleButtonSmall from "./ToggleButtonSmall";

interface StyleProps {
  variant?: string;
  dropdownIsOpen: boolean;
}

const StyledTitle = styled.span<StyleProps>`
  cursor: pointer;
  align-items: left;
  font-weight: ${(props) => props.theme.font_weight.bolder};
  padding: 0.5rem 1rem 0.25rem;
  border-radius: ${(props) =>
    props.dropdownIsOpen
      ? `${props.theme.border_radius.SM} ${props.theme.border_radius.SM} 0 0`
      : props.theme.border_radius.SM};
  ${(props) =>
    variant({
      variants: {
        default: { backgroundColor: props.theme.colors.backgroundSecondary },
        success: { backgroundColor: props.theme.colors.backgroundSecondary },
        error: { backgroundColor: props.theme.colors.danger },
        warning: { backgroundColor: props.theme.colors.warning },
      },
    })}
`;

StyledTitle.defaultProps = {
  variant: "default", // Ensure 'default' is applied if no variant is passed
};

const StyledDropdown = styled(Row)`
  border-bottom-left-radius: ${(props) => props.theme.border_radius.SM};
  border-bottom-right-radius: ${(props) => props.theme.border_radius.SM};
  background-color: ${(props) => props.theme.colors.grey10};
`;

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledKey = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
  cursor: pointer;
  decoration: underline;
  color: ${(props) => props.theme.colors.blue};
`;

const StyledText = styled.span`
  margin-left: 0.25rem;
  color: ${(props) => props.theme.colors.seaBlue};
  font-color: ${(props) => props.theme.colors.blue};
  font-weight: ${(props) => props.theme.font_weight.regular};
`;

const StyledContent = styled(Row)`
  background-color: ${(props) => props.theme.colors.grey10};
  color: ${(props) => props.theme.colors.seaBlue};
  white-space: "pre";
  overflow-x: "auto";
`;

interface CollapsibleJsonProps {
  title: string;
  json: GenericObject;
  type?: "error" | "success" | "warning";
  formatted?: boolean; // true = will just pretty print the JSON or false = as a string
  collapsible?: boolean; // true = will allow the user to partially collapse the JSON, "formatted" is ignored
  isOpen?: boolean;
}

const CollapsibleJson = ({
  title,
  json,
  type,
  isOpen,
  formatted,
  collapsible,
}: CollapsibleJsonProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(isOpen || false);
  const [expandedKeys, setExpandedKeys] = useState<Set<string>>(new Set());
  const [showCollapsible, setShowCollapsible] = useState(collapsible);
  const [showPretty, setShowPretty] = useState(formatted);

  const toggleDropdown = () => {
    setDropdownOpen((oldState) => !oldState);
  };

  const toggleExpand = (key: string) => {
    const newExpandedKeys = new Set(expandedKeys);
    if (newExpandedKeys.has(key)) {
      newExpandedKeys.delete(key);
    } else {
      newExpandedKeys.add(key);
    }
    setExpandedKeys(newExpandedKeys);
  };

  // Recursively render the JSON content
  const renderJsonContent = (value: any, path: string) => {
    if (!showCollapsible) {
      // Render JSON content as a string
      return showPretty ? (
        <Div>
          <StyledText>
            <pre>{JSON.stringify(value, null, 2)}</pre>
          </StyledText>
        </Div>
      ) : (
        <Div>
          <StyledText>{JSON.stringify(value)}</StyledText>
        </Div>
      );
    }

    // Render JSON content as a collapsible object
    if (Array.isArray(value)) {
      // Array
      return (
        <StyledText>
          <StyledContent>[</StyledContent>
          {value.map((item, index) => (
            <StyledContent>
              <StyledKey onClick={() => toggleExpand(`${path}-${index}`)}>
                {index}:
              </StyledKey>
              {expandedKeys.has(`${path}-${index}`) ? (
                <StyledText key={`${path}-${index}`}>
                  {renderJsonContent(item, `${path}-${index}`)}
                  {index < value.length - 1 && " ,"}
                </StyledText>
              ) : (
                <>
                  {typeof item === "object"
                    ? "{...}"
                    : JSON.stringify(item, null, 2)}
                </>
              )}
            </StyledContent>
          ))}
          ]
        </StyledText>
      );
    } else if (typeof value === "object" && value !== null) {
      // Dictionary
      return (
        <>
          {"{"}
          {Object.entries(value).map(([key, val], index) => (
            <StyledContent key={`${path}-${key}`}>
              <StyledKey
                onClick={() => {
                  if (typeof value[key] === "object")
                    toggleExpand(`${path}-${key}`);
                }}
              >
                {key}:
              </StyledKey>

              {expandedKeys.has(`${path}-${key}`) ? (
                <StyledLabel>
                  {renderJsonContent(val, `${path}-${key}`)}
                </StyledLabel>
              ) : (
                <>
                  {typeof val === "object"
                    ? "{...}"
                    : JSON.stringify(val, null, 2)}
                </>
              )}
              {index < Object.entries(value).length - 1}
            </StyledContent>
          ))}
          {"}"}
        </>
      );
    }
  };

  // Build the component
  return (
    <Container>
      <StyledTitle
        variant={type}
        dropdownIsOpen={dropdownOpen}
        onClick={(e) => {
          toggleDropdown();
          e.stopPropagation();
        }}
      >
        <Row justifyContent="space-between">
          {title}{" "}
          <StyledKey style={{ color: "white" }}>
            {dropdownOpen ? <FaCaretDown /> : <FaCaretUp />}
          </StyledKey>
          <ToggleButtonSmall
            initialToggleState={showCollapsible || false}
            onToggle={() => {
              collapsible && setShowCollapsible((prevState) => !prevState); // toggle collapsible
              !collapsible && setShowPretty((prevState) => !prevState); // or formatted print
            }}
          />
        </Row>
      </StyledTitle>
      {dropdownOpen && (
        <StyledDropdown pt={{ default: 2 }} pb={{ default: 2 }}>
          {renderJsonContent(json, "root")}
        </StyledDropdown>
      )}
    </Container>
  );
};

export default CollapsibleJson;
