import { useEffect, useState } from "react";
import Modal from "./Modal";
import Button from "./Button";
import Row from "./Row";
import Div from "./Div";
import ErrorMessage from "../baseComponents/ErrorMessage";
import styled from "styled-components";

/**
 * ConfirmationModal — react component to display a modal confirmation window
 *
 * @confirmationOpen {boolean} : show/hide confirmation modal
 * @onClose {function} : callback on close modal
 * @onConfirm {function} : callback on confirm button pressed
 * @message {string} : text to show on modal
 *
 **/

interface ConfirmationModalProps {
  confirmationOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  errorMessage?: string;
  message?: string;
  title?: string;
  buttonText?: string;
  buttonType?:
    | "primary"
    | "primary_sm"
    | "secondary"
    | "secondary_sm"
    | "primary_slim"
    | "secondary_slim"
    | "disabled"
    | "disabled_sm"
    | "back"
    | "warning"
    | "danger"
    | "success";
  variant?: string;
  sizeVariant?: string;
  children?: React.ReactNode;
}

const StyledMessage = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  margin-bottom: 1rem;
  white-space: pre-line;
`;

const StyledTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledModal = styled(Modal)`
  padding: 1rem;
`;

const ConfirmationModal = ({
  confirmationOpen,
  onClose,
  onConfirm,
  errorMessage,
  message,
  title,
  buttonText,
  buttonType,
  variant,
  sizeVariant,
  children,
}: ConfirmationModalProps) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    onConfirm();
  };

  const handleClose = () => {
    if (!loading || errorMessage) {
      onClose();
    }
  };

  useEffect(() => {
    if (!confirmationOpen) {
      setLoading(false);
    }
  }, [confirmationOpen]);

  return (
    <StyledModal
      showModal={confirmationOpen}
      closeModal={handleClose}
      variant={variant}
      sizeVariant={sizeVariant}
    >
      <>
        {children}
        <Row justifyContent="center">
          <Div width={{ default: 1 }} alignItems="center">
            {title && (
              <Row addSpace={false}>
                <StyledTitle>{title}</StyledTitle>
              </Row>
            )}
            <StyledMessage mt={{ default: 3 }} width={{ default: 1 }}>
              {message ??
                "Please confirm you would like to take the previous action"}
            </StyledMessage>
            {errorMessage && (
              <Row mt={{ default: 3 }} justifyContent="center">
                <ErrorMessage textAlign={"center"}>{errorMessage}</ErrorMessage>
              </Row>
            )}
            {!errorMessage && (
              <Div width={{ default: 1, lg: 8 / 12 }} mt={{ default: 3 }}>
                <Button
                  onClick={handleConfirm}
                  loading={loading}
                  type={buttonType}
                >
                  {buttonText ? buttonText : "Confirm"}
                </Button>
              </Div>
            )}
          </Div>
        </Row>
      </>
    </StyledModal>
  );
};

export default ConfirmationModal;
