import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import styled, { css } from "styled-components";
import Div from "../../../../components/baseComponents/Div";
import Row from "../../../../components/baseComponents/Row";
import NucleusLoader from "../../../components/NucleusLoader";
import { dateShortFormatter } from "../../../../components/global/utils";
import { GenericObject } from "../../../../components/global/ModelInterfaces";
import { getRentManagerFailedInvites } from "../../../utils/rentManagerUtils";
import ErrorMessage from "../../../../components/baseComponents/ErrorMessage";

const StyledTitle = styled(Row)`
  font-size: ${(props) => props.theme.font_size.headlineMD};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const RentManagerFailedInvites = () => {
  const [loading, setLoading] = useState(true);
  const [failedInvites, setFailedInvites] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (isEmpty(failedInvites)) {
      const successCallback = (data: any) => {
        setFailedInvites(data);
        setLoading(false);
      };
      const failureCallback = (error: string) => {
        setError(error);
        setLoading(false);
      };
      getRentManagerFailedInvites(successCallback, failureCallback);
    }
  }, []);

  if (loading) {
    return (
      <Div>
        <Row justifyContent="center" mt={{ default: 5 }}>
          <NucleusLoader loadingText="Loading Failed Invites" />
        </Row>
      </Div>
    );
  } else if (error) {
    return (
      <div>
        <Row>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      </div>
    );
  }
  return (
    <Div width={{ default: 1 }} alignItems="center">
      <StyledTitle
        mt={{ default: 3 }}
        mb={{ default: 4 }}
        justifyContent="center"
      >
        <span>Current Failed Invites</span>
      </StyledTitle>
      <Div width={{ default: 10 / 12 }}>
        <FailedInviteTable failedInvites={failedInvites} />
      </Div>
    </Div>
  );
};

interface FailedInviteTableProps {
  failedInvites: Array<GenericObject>;
}

const FailedInviteTable = ({ failedInvites }: FailedInviteTableProps) => {
  const TableStyle = css`
    border: 1px solid ${(props) => props.theme.colors.black};
    border-collapse: collapse;
    padding-left: 10px;
    font-size: ${(props) => props.theme.font_size.bodyMD};
  `;

  const StyledTableRow = styled.tr`
    table-layout: fixed;
  `;

  const StyledTableHeader = styled.th`
    ${TableStyle}
  `;
  const StyledTableSection = styled.td`
    ${TableStyle}
  `;

  return (
    <table>
      <StyledTableRow>
        <StyledTableHeader>Initial Fail Date</StyledTableHeader>
        <StyledTableHeader>Latest Fail Date</StyledTableHeader>
        <StyledTableHeader>Company</StyledTableHeader>
        <StyledTableHeader>Location Name</StyledTableHeader>
        <StyledTableHeader>Resource ID</StyledTableHeader>
        <StyledTableHeader>Email</StyledTableHeader>
        <StyledTableHeader>Error</StyledTableHeader>
        <StyledTableHeader>Details</StyledTableHeader>
      </StyledTableRow>
      {failedInvites.map((failedInvite) => {
        return (
          <StyledTableRow>
            <StyledTableSection>
              {dateShortFormatter(failedInvite.created_at)}
            </StyledTableSection>
            <StyledTableSection>
              {dateShortFormatter(failedInvite.updated_at)}
            </StyledTableSection>
            <StyledTableSection>{failedInvite.company}</StyledTableSection>
            <StyledTableSection>
              {failedInvite.location_name}
            </StyledTableSection>
            <StyledTableSection>{failedInvite.resource_id}</StyledTableSection>
            <StyledTableSection>{failedInvite.tenant_email}</StyledTableSection>
            <StyledTableSection>{failedInvite.errors}</StyledTableSection>
            <StyledTableSection>
              {failedInvite.details.split("\n").map((line: string) => (
                <>
                  {line}
                  <br />
                </>
              ))}
            </StyledTableSection>
          </StyledTableRow>
        );
      })}
    </table>
  );
};

export default RentManagerFailedInvites;
