import { useState } from "react";
import { find } from "lodash";
import BeatLoader from "react-spinners/BeatLoader";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Dropdown from "../../../../../components/baseComponents/Dropdown";
import Input from "../../../../../components/baseComponents/Input";
import Button from "../../../../../components/baseComponents/Button";
import SuccessMessage from "../../../../../components/baseComponents/SuccessMessage";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";
import { inviteTenantById } from "../../../../utils/yardiUtils";
import {
  Building,
  GenericObject,
  Option,
} from "../../../../../components/global/ModelInterfaces";

const TenantInviteForm = () => {
  const { company } = useCompanyFromParams();
  const [loading, setLoading] = useState(false);
  const [tenantID, setTenantID] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(
    null
  );
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const buildingOptions = company.buildings.map((building: Building) => ({
    label: building.name,
    id: building.uuid,
  }));

  const selectBuilding = (option: Option) => {
    const building = find(
      company.buildings,
      (building) => building.uuid === option.id
    );
    setSelectedBuilding(building);
  };

  const resetInputs = () => {
    setSuccess((prev) => prev && "");
    setError((prev) => prev && "");
  };

  const handleManualInvite = () => {
    if (!selectedBuilding) {
      setError("A building needs to be chosen from the dropdown.");
      return;
    }

    if (!tenantID) {
      setError("A tenant ID needs to be input.");
      return;
    }

    setLoading(true);
    resetInputs();

    const successCallback = (res: GenericObject) => {
      setSuccess(res.message);
      setLoading(false);
    };
    const failureCallback = (error: string) => {
      setError(error);
      setLoading(false);
    };

    inviteTenantById(
      selectedBuilding.uuid,
      tenantID,
      successCallback,
      failureCallback
    );
  };

  return (
    <>
      <Row justifyContent="center">
        <Div width={{ lg: 10 / 12 }}>
          <Dropdown
            options={buildingOptions}
            name="Building Dropdown"
            placeholder=""
            onClick={selectBuilding}
            value={
              selectedBuilding
                ? { label: selectedBuilding.name, id: selectedBuilding.uuid }
                : null
            }
          />
        </Div>
      </Row>
      <Row justifyContent="center">
        <Div width={{ lg: 10 / 12 }}>
          <Input
            type="text"
            label="Yardi Tenant ID"
            onChange={(e) => setTenantID(e.target.value)}
          />
        </Div>
      </Row>
      <Row justifyContent="center">
        <Div>
          {loading ? (
            <BeatLoader />
          ) : (
            <Button text="Invite Tenant" onClick={handleManualInvite} />
          )}
        </Div>
      </Row>

      {success && (
        <Row justifyContent="center" mt={{ default: 3 }}>
          <SuccessMessage>{success}</SuccessMessage>
        </Row>
      )}

      {error && (
        <Row justifyContent="center" mt={{ default: 3 }}>
          <ErrorMessage>{error}</ErrorMessage>
        </Row>
      )}
    </>
  );
};

export default TenantInviteForm;
