import styled from "styled-components";
import { useState } from "react";

import Button from "../../../../../components/baseComponents/Button";
import Div from "../../../../../components/baseComponents/Div";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import Input from "../../../../../components/baseComponents/Input";
import Row from "../../../../../components/baseComponents/Row";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";

import TenantLedgerModal from "./TenantLedgerModal";
import DatabaseSelect from "./DatabaseSelect";
import { retrieveTenantLedger } from "../../../../utils/yardiUtils";
import { YardiDatabaseOption } from "../interfaces/YardiIntegrationDetailsInterface";

const StyledInstructions = styled(Div)`
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const TenantLedgerSelect = () => {
  const [loading, setLoading] = useState(false);
  const [selectedDatabase, setSelectedDatabase] =
    useState<YardiDatabaseOption | null>(null);
  const [tenantID, setTenantID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ledgerData, setLedgerData] = useState<GenericObject>({});
  const [modalOpen, setModalOpen] = useState(false);
  const { company } = useCompanyFromParams();

  const handleSubmit = () => {
    if (!selectedDatabase || !tenantID) {
      setErrorMessage("You must choose a database and enter a tenant ID.");
      return;
    }

    const successCallBack = (ledgerData: GenericObject) => {
      setLoading(false);
      setErrorMessage("");
      setLedgerData(ledgerData);
      setModalOpen(true);
    };
    const failureCallback = (err: string) => {
      setModalOpen(false);
      setLedgerData({});
      setErrorMessage(`Error retrieving tenant ledger: ${err}`);
      setLoading(false);
    };
    setLoading(true);
    setModalOpen(false);
    setErrorMessage("");
    setLedgerData({});
    retrieveTenantLedger(
      tenantID,
      selectedDatabase.name,
      company.integration_details.yardi_company_code,
      successCallBack,
      failureCallback
    );
  };

  return (
    <Div>
      <Row>
        <StyledInstructions mb={{ default: 3 }}>
          Choose Database to Get Resource List from:
        </StyledInstructions>
      </Row>
      <DatabaseSelect
        handleSelect={(selectedDatabase: YardiDatabaseOption) => {
          setSelectedDatabase(selectedDatabase);
        }}
      />
      <Row mb={{ default: 3 }}>
        <Div width={{ default: 1 }}>
          <Input
            value={tenantID}
            placeholderText="e.g. t0000001"
            onChange={(e) => setTenantID(e.target.value)}
            label="Tenant ID"
          />
        </Div>
      </Row>
      {errorMessage && (
        <Row justifyContent="center" mb={{ default: 3 }}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Row>
      )}
      <Row>
        <Div width={{ default: 1 }}>
          <Button
            loading={loading}
            text="Retrieve Tenant Ledger"
            onClick={handleSubmit}
          />
        </Div>
      </Row>
      <TenantLedgerModal
        ledgerData={ledgerData}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </Div>
  );
};

export default TenantLedgerSelect;
