import { useState } from "react";
import styled from "styled-components";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import RentableCard from "../../baseComponents/RentableCard";
import ErrorMessage from "../../baseComponents/ErrorMessage";
import DocumentIcon from "../../../images/DocumentTermsIcon";
import DocumentTermsIcon from "../../../images/DocumentTermsIcon";
import { GenericObject, Document } from "../../global/ModelInterfaces";
import {
  handleDownloadW9Pdf,
  downloadReceipt,
  downloadStatementOfCondition,
  handleDocumentDownload,
  downloadDeductionsReceipt,
  downloadFeesReceipt,
} from "../../../utils/documentUtils";
import { COLORS } from "../../global/colors";
import prepaymentStatuses from "../../../enums/prepaymentStatuses";
import documentTypes from "../../../enums/documentTypes";
import { getStateRule, rulesList } from "../../global/StateRules/StateRules";
import BeatLoader from "react-spinners/BeatLoader";
interface Props {
  prepaymentInfo: GenericObject;
}

const StyledDownloadLink = styled(Row)`
  color: ${COLORS.blue};
  cursor: pointer;
`;

const StyledHR = styled(Div)`
  margin-top: 1em;
`;
const DocumentsCard = ({ prepaymentInfo }: Props) => {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState("");
  const w9 = prepaymentInfo?.document_list?.find(
    (document: Document) => document.type === documentTypes.W_9
  );

  const handleDownloadDeductionsReceipt = () => {
    setDownloading(true);
    setError("");
    const successCallback = () => {
      setDownloading(false);
    };
    const failureCallback = () => {
      setDownloading(false);
      setError("There was an error downloading your deductions receipt.");
    };
    downloadDeductionsReceipt(
      prepaymentInfo.deposits.security_deposit.uuid,
      successCallback,
      failureCallback
    );
  };

  return (
    <RentableCard title={"Documents & Receipts"}>
      <>
        {w9 && prepaymentInfo.deposits.security_deposit && (
          <StyledHR>
            <Div
              onClick={() => {
                if (w9.name) {
                  handleDocumentDownload(w9.uuid, w9.name);
                } else {
                  handleDownloadW9Pdf(prepaymentInfo.prepayment_uuid);
                }
              }}
            >
              <StyledDownloadLink>
                <Div>
                  <DocumentIcon />
                </Div>
                <span>IRS W9 Form</span>
              </StyledDownloadLink>
            </Div>
          </StyledHR>
        )}
        {prepaymentInfo.deposits.security_deposit && (
          <>
            <Div
              onClick={() => {
                downloadReceipt(
                  prepaymentInfo.deposits.security_deposit.uuid,
                  "security_deposit"
                );
              }}
            >
              <StyledDownloadLink>
                <Div>
                  <DocumentIcon />
                </Div>
                <span>Security Deposit Receipt</span>
              </StyledDownloadLink>
            </Div>
          </>
        )}
        {prepaymentInfo.renters_insurance_enrollment &&
          prepaymentInfo.renters_insurance_enrollment.doc_url && (
            <>
              <StyledHR>
                <a
                  href={prepaymentInfo.renters_insurance_enrollment.doc_url}
                  download
                >
                  <StyledDownloadLink>
                    <Div>
                      <DocumentIcon />
                    </Div>
                    <span>Renters Insurance Info.</span>
                  </StyledDownloadLink>
                </a>
              </StyledHR>
            </>
          )}
        {prepaymentInfo.deposits.last_months_rent && (
          <>
            <StyledHR
              onClick={() => {
                downloadReceipt(
                  prepaymentInfo.deposits.last_months_rent.uuid,
                  "last_months_rent"
                );
              }}
            >
              <StyledDownloadLink>
                <Div>
                  <DocumentTermsIcon />
                </Div>
                <span>Last Month's Rent Receipt</span>
              </StyledDownloadLink>
            </StyledHR>
          </>
        )}
        {getStateRule({
          state: prepaymentInfo.state,
          rule: rulesList.SHOW_STATEMENT_OF_CONDITION,
        }) && (
          <>
            <StyledHR onClick={downloadStatementOfCondition}>
              <StyledDownloadLink>
                <Div>
                  <DocumentTermsIcon />
                </Div>
                <span>Statement of Condition Form</span>
              </StyledDownloadLink>
            </StyledHR>
          </>
        )}
        {prepaymentInfo.fees.length > 0 && (
          <>
            <StyledHR
              onClick={() => {
                downloadFeesReceipt(prepaymentInfo.prepayment_uuid);
              }}
            >
              <StyledDownloadLink>
                <Div>
                  <DocumentTermsIcon />
                </Div>
                <span>Fees Receipt</span>
              </StyledDownloadLink>
            </StyledHR>
          </>
        )}
        {prepaymentInfo.deposits.security_deposit &&
          [prepaymentStatuses.DISBURSING, prepaymentStatuses.CLOSED].includes(
            prepaymentInfo.deposits.security_deposit.status_id
          ) && (
            <>
              {downloading ? (
                <Div
                  mt={{ default: 3 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <span>Downloading deductions receipt...</span>
                  <BeatLoader />
                </Div>
              ) : (
                <StyledHR onClick={handleDownloadDeductionsReceipt}>
                  <StyledDownloadLink>
                    <Div>
                      <DocumentIcon />
                    </Div>
                    <span>Itemized Deduction List Statement</span>
                  </StyledDownloadLink>
                </StyledHR>
              )}
            </>
          )}

        {prepaymentInfo.document_list.map((document: Document) => {
          if (document.type !== documentTypes.W_9) {
            return (
              <>
                <StyledHR
                  onClick={() => {
                    handleDocumentDownload(document.uuid, document.name);
                  }}
                >
                  <StyledDownloadLink>
                    <Div>
                      <DocumentTermsIcon />
                    </Div>
                    <span>{document.name}</span>
                  </StyledDownloadLink>
                </StyledHR>
              </>
            );
          }
        })}
        {error && (
          <Div mt={{ default: 3 }}>
            <ErrorMessage>{error}</ErrorMessage>
          </Div>
        )}
      </>
    </RentableCard>
  );
};

export default DocumentsCard;
