import { Route, Switch } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import NucleusLogin from "../pages/NucleusLogin";
import NucleusDashboard from "../pages/Dashboard";
import NucleusTestRequest from "../pages/NucleusTestRequest";
import PrepaymentSearch from "../pages/PrepaymentSearch";
import PrepaymentOverview from "../pages/PrepaymentOverview";
import InvitePage from "../pages/InvitePage";
import SignupPage from "../pages/SignupPage";
import CompanyOverview from "../pages/CompanyOverview";
import CompanySearch from "../pages/CompanySearch";
import ForbiddenPage from "../pages/Forbidden";
import IntegrationsDashboard from "../pages/Integrations/IntegrationsDashboard";
import rentManagerNucleusRoutes from "./RentManagerRoutes";
import YardiDashboard from "../pages/Integrations/yardi/YardiTab";
import AsyncDashboard from "../pages/async/AsyncDashboard";
import AdminPage from "../pages/AdminPage";
import SendTestEmail from "../pages/SendTestEmail";

const RoutesNucleus = () => {
  const currentUser = useAppSelector((state) => state.auth.user);

  if (!currentUser || !currentUser.nucleus_key) {
    return (
      <Switch>
        <Route exact path="/nucleus/sign-up" component={SignupPage} />
        <Route exact path="*" component={NucleusLogin}></Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path="/nucleus/prepayment-search"
        component={PrepaymentSearch}
      />
      <Route
        exact
        path="/nucleus/prepayment-overview/:prepaymentUUID"
        component={PrepaymentOverview}
      />
      <Route exact path="/nucleus/company-search" component={CompanySearch} />
      <Route
        exact
        path="/nucleus/company-overview/:companyUUID"
        component={CompanyOverview}
      />
      <Route
        exact
        path="/nucleus/integrations"
        component={IntegrationsDashboard}
      />
      
      {rentManagerNucleusRoutes.map((route) => (
        <Route exact path={route.path} component={route.component} />
      ))}

      {/* Yardi Routes Below */}
      <Route
        exact
        path="/nucleus/integrations/yardi"
        component={YardiDashboard}
      />
      {/* Yardi ends */}

      <Route exact path="/nucleus/async" component={AsyncDashboard} />
      <Route exact path="/nucleus/admin/invite-user" component={InvitePage} />
      <Route
        exact
        path="/nucleus/admin/send-test-email"
        component={SendTestEmail}
      />
      <Route exact path="/nucleus/admin" component={AdminPage} />
      <Route exact path="/nucleus/test" component={NucleusTestRequest} />
      <Route exact path="/nucleus/login" component={NucleusLogin} />
      <Route exact path="/nucleus/forbidden" component={ForbiddenPage} />
      <Route exact path="/nucleus/dashboard-test" component={ForbiddenPage} />
      <Route exact path="*" component={NucleusDashboard} />
    </Switch>
  );
};

export default RoutesNucleus;
