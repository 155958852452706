import { useHistory, useParams } from "react-router-dom";
import Div from "../../baseComponents/Div";
import Button from "../../baseComponents/Button";
import RentableBanner from "../../baseComponents/RentableBanner";
import { GenericObject } from "../../global/ModelInterfaces";
import RentableHugCoin from "../../../images/RentableHugCoin";

const RenewalOfferBanner = () => {
  const history = useHistory();
  const { prepaymentUUID } = useParams<GenericObject>();
  return (
    <RentableBanner title="* Renewal Offer *">
      <RentableHugCoin />
      <Div justifyContent="center" alignItems="center">
        Your landlord has renewed your lease recently and you may be eligible to
        withdraw your Security Deposit funds today!
        <Div mt={{ default: 4 }} width={{ default: 1 / 2 }}>
          <Button
            text="Learn More"
            onClick={() => {
              history.push(`/tenant/renewal-plan-details/${prepaymentUUID}`);
            }}
          />
        </Div>
      </Div>
    </RentableBanner>
  );
};
export default RenewalOfferBanner;
